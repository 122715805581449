import { useAuthStore } from '@/stores/auth_store';
import router from '@/router'

export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    patch: request('PATCH'),
    delete: request('DELETE')
};

function request(method) {
    return (url, body) => {
        const requestOptions = {
            method,
            headers: authHeader(url)
        };
        if (body) {
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.body = JSON.stringify(body);
        }
        return fetch(url, requestOptions).then(handleResponse).catch(handleResponseError);
    }
}

// helper functions

function authHeader(url) {
    // return auth header with jwt if user is logged in and request is to the api url
    const { authToken } = useAuthStore();
    const isLoggedIn = !!authToken?.access;
    const isApiUrl = url.startsWith(import.meta.env.VITE_BASE_URL);

    if (isLoggedIn && isApiUrl) {
        return { Authorization: `Bearer ${authToken.access}` };
    } else {
        return {};
    }
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const { user, logout } = useAuthStore();
            if (response.status === 403) {
                // router.push('/forbidden');
            }
            // if ([401, 403].includes(response.status) && user) {
            if ([401].includes(response.status) && user) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject({ status: response.status, error, data });
        }

        return Promise.resolve(data);
    });
}

function handleResponseError(error) {
    if (error.status === 403) {
        // router.push('/forbidden');

    }

    return Promise.reject(error)
}