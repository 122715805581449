import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { fetchWrapper } from '@/helpers/fetch-wrapper';
import { parseMoney, strToFloatMoney } from '@/money-options'


const baseUrl = `${import.meta.env.VITE_BASE_URL}`;


export const useCartStore = defineStore('cart', () => {
  const cart = ref(JSON.parse(localStorage.getItem('cart')))
  if (!cart.value || cart.value === "undefined") {
    cart.value = emptyCart()
    saveCart()
  }
  const itemsInCart = computed(() => {
    let items = []
    if (cart.value.items.length) {
      cart.value.items.forEach((item) => items.push({ id: item.id, state: item.state, type: item.type }))
    }
    return items
  })


  function calcItemsInCart() {
    let items = []
    if (cart.value.items.length) {
      cart.value.items.forEach((item) => items.push(item.id))
    }
    return items
  }

  function calcTotal() {
    let total = 0.0
    cart.value.items.forEach((item) => total += strToFloatMoney(item.total))
    cart.value.total = total
    // console.log(cart.value.total)
  }
  function emptyCart() { return { items: [], total: 0 } }
  function clearCart() {
    cart.value = emptyCart()
    saveCart()
  }
  function saveCart() {
    calcTotal()
    localStorage.setItem('cart', JSON.stringify(cart.value))
  }
  const cartLength = computed(() => cart.value.items.length)

  function addItemToCart(item) {
    item.amountError = false
    if (!isItemInCart(item)) {
      cart.value.items.push(item)
    } else {
      const idx = cart.value.items.findIndex((oldItem) => oldItem.id == item.id && oldItem.state == item.state && oldItem.type == item.type)
      cart.value.items[idx] = item
    }
    saveCart()

  }
  function getItemFromCart(item) {
    return cart.value.items.find((itemInCart) => (itemInCart.id === item.id && itemInCart.state === item.state && itemInCart.type === item.type))
  }
  function removeItemFromCart(item) {
    cart.value.items = cart.value.items.filter((itemInCart) => !(itemInCart.id === item.id && itemInCart.state === item.state && itemInCart.type === item.type))
    saveCart()
  }
  function isItemInCart(item) {
    return getItemFromCart(item) !== undefined
  }
  function addAmountErrors(sharepart_ids = [], equipments_ids = [], doneproducts_ids = []) {
    cart.value.items.forEach(item => {
      console.log(item.type, item.itemInWhId, sharepart_ids, equipments_ids, doneproducts_ids, item)
      if (item.type === 'sharepart' && sharepart_ids.includes(String(item.itemInWhId))) {
        item.amountError = true
      } else if (item.type === 'equipment' && equipments_ids.includes(String(item.itemInWhId))) {
        item.amountError = true
      } else if (item.type === 'doneproduct' && doneproducts_ids.includes(String(item.itemInWhId))) {
        item.amountError = true
      }
    })
  }
  function clearAmountErrors() {
    cart.value.forEach(item => { item.amountError = false })
  }
  async function payForTheCart(sale) {
    let form = { shareparts: [], equipments: [], doneproducts: [], total: cart.value.total }
    cart.value.items.forEach((item) => {
      item.amountError = false
      if (item.type === 'sharepart') {
        form.shareparts.push(
          {
            item_in_wh_id: item.itemInWhId,
            share_part_id: item.id,
            state: item.state,
            amount: strToFloatMoney(item.amount),
            price: strToFloatMoney(item.price),
            total: strToFloatMoney(item.total)
          }
        )
      } else if (item.type === 'equipment') {
        form.equipments.push(
          {
            item_in_wh_id: item.itemInWhId,
            equipment_id: item.id,
            state: item.state,
            amount: strToFloatMoney(item.amount),
            price: strToFloatMoney(item.price),
            total: strToFloatMoney(item.total)
          }
        )
      } else if (item.type === 'doneproduct') {
        form.doneproducts.push(
          {
            item_in_wh_id: item.itemInWhId,
            doneproduct_id: item.id,
            state: item.state,
            amount: strToFloatMoney(item.amount),
            price: strToFloatMoney(item.price),
            total: strToFloatMoney(item.total)
          }
        )
      }

    })
    Object.assign(sale, form);
    return new Promise((resolve, reject) => {
      fetchWrapper.post(`${baseUrl}/sales/sales/`, sale).then(() => {
        clearCart()
        resolve('ok')
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }

  return {
    cart,
    cartLength,
    itemsInCart,
    addItemToCart,
    removeItemFromCart,
    getItemFromCart,
    clearCart,
    isItemInCart,
    calcItemsInCart,
    payForTheCart,
    addAmountErrors,
    clearAmountErrors
  }
})
