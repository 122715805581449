<script setup>
import { mdiLogout, mdiClose, mdiWeb } from '@mdi/js'
import { computed } from 'vue'
import AsideMenuList from '@/components/AsideMenu/AsideMenuList.vue'
import AsideMenuItem from '@/components/AsideMenu/AsideMenuItem.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import { useAuthStore } from '@/stores/auth_store'
import FormField from '@/components/FormField.vue'
import FormControl from '@/components/FormControl.vue'
import { useMainStore } from '@/stores/main'
import { useI18n } from 'vue-i18n'
const { t, locale, availableLocales } = useI18n()
const auth = useAuthStore()
const mainStore = useMainStore()

defineProps({
  menu: {
    type: Array,
    required: true
  }
})

const emit = defineEmits(['menu-click', 'aside-lg-close-click'])

const logoutItem = computed(() => ({
  label: t('menuAside.logout'),
  icon: mdiLogout,
  color: 'info',
  isLogout: true
}))

const menuClick = (event, item) => {
  emit('menu-click', event, item)
}
function logoutClick() {
  auth.logout()
}

const asideLgCloseClick = (event) => {
  emit('aside-lg-close-click', event)
}

async function changeLanguage(value) {
  mainStore.saveCurrentLanguage(value)
}
</script>

<template>
  <aside
    id="aside"
    class="lg:py-2 lg:pl-2 w-60 fixed flex z-40 top-0 h-screen transition-position overflow-hidden"
  >
    <div class="aside lg:rounded-2xl flex-1 flex flex-col overflow-hidden dark:bg-slate-900">
      <div class="aside-brand flex flex-row h-14 items-center justify-between dark:bg-slate-900">
        <div class="text-center flex-1 lg:text-left lg:pl-6 xl:text-center xl:pl-0">
          <b class="font-black">{{ t('menuAside.menu') }}</b>
        </div>
        <button class="hidden lg:inline-block xl:hidden p-3" @click.prevent="asideLgCloseClick">
          <BaseIcon :path="mdiClose" />
        </button>
      </div>
      <div
        class="flex-1 overflow-y-auto overflow-x-hidden aside-scrollbars dark:aside-scrollbars-[slate]"
      >
        <AsideMenuList :menu="menu" @menu-click="menuClick" />
      </div>
      <FormField>
        <FormControl
          v-model="locale"
          :allow-empty="false"
          :icon="mdiWeb"
          :options="availableLocales"
          name="language"
          @update:model-value="changeLanguage"
        />
      </FormField>
      <ul>
        <AsideMenuItem :item="logoutItem" @click="logoutClick" />
      </ul>
    </div>
  </aside>
</template>
