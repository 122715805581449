import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import axios from 'axios'
import i18n from "@/i18n"

export const useMainStore = defineStore('main', () => {
  const userName = ref('John Doe')
  const userEmail = ref('doe.doe.doe@example.com')
  const timeZone = ref(JSON.parse(localStorage.getItem('timeZone')))
  const language = ref(JSON.parse(localStorage.getItem('language')))
  if (!timeZone.value || timeZone.value === "undefined") {
    timeZone.value = `${import.meta.env.VITE_TIMEZONE}`
    saveTimeZoneInLocalStorage(timeZone.value)
  }
  if (!language.value || language.value === "undefined") {
    language.value = i18n.global.fallbackLocale.value
    saveCurrentLanguage(language.value)
  } else if (!i18n.global.availableLocales.includes(language.value)) {
    language.value = i18n.global.fallbackLocale.value
    saveCurrentLanguage(language.value)
    i18n.global.locale.value = "en"
  }


  const userAvatar = computed(
    () =>
      `https://api.dicebear.com/7.x/avataaars/svg?seed=${userEmail.value.replace(
        /[^a-z0-9]+/gi,
        '-'
      )}`
  )

  const isFieldFocusRegistered = ref(false)

  const clients = ref([])
  const history = ref([])

  function setUser(payload) {
    if (payload.name) {
      userName.value = payload.name
    }
    if (payload.email) {
      userEmail.value = payload.email
    }
  }

  function fetchSampleClients() {
    axios
      .get(`data-sources/clients.json?v=3`)
      .then((result) => {
        clients.value = result?.data?.data
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  function fetchSampleHistory() {
    axios
      .get(`data-sources/history.json`)
      .then((result) => {
        history.value = result?.data?.data
      })
      .catch((error) => {
        alert(error.message)
      })
  }
  function saveTimeZoneInLocalStorage(value) {
    localStorage.setItem('timeZone', JSON.stringify(value))
  }
  function saveCurrentLanguage(value) {
    localStorage.setItem('language', JSON.stringify(value))
  }

  return {
    userName,
    userEmail,
    userAvatar,
    isFieldFocusRegistered,
    clients,
    history,
    timeZone,
    language,
    setUser,
    fetchSampleClients,
    fetchSampleHistory,
    saveTimeZoneInLocalStorage,
    saveCurrentLanguage
  }
})
