<script setup>
import { computed, ref, onMounted } from 'vue'
import { useMainStore } from '@/stores/main'
import {
  mdiAccountMultiple,
  mdiWheelBarrow,
  mdiCartOutline,
  mdiChartTimelineVariant,
  mdiMonitorCellphone,
  mdiReload,
  // mdiGithub,
  mdiChartPie
} from '@mdi/js'
import * as chartConfig from '@/components/Charts/chart.config.js'
import LineChart from '@/components/Charts/LineChart.vue'
import SectionMain from '@/components/SectionMain.vue'
import CardBoxWidgetCostPrice from '@/components/CardBoxWidgetCostPrice.vue'
import CardBox from '@/components/CardBox.vue'
import TableSampleClients from '@/components/Clients/TableSampleClients.vue'
import NotificationBar from '@/components/NotificationBar.vue'
import BaseButton from '@/components/BaseButton.vue'
import CardBoxTransaction from '@/components/CardBoxTransaction.vue'
import CardBoxClient from '@/components/CardBoxClient.vue'
import LayoutAuthenticated from '@/layouts/LayoutAuthenticated.vue'
import SectionTitleLineWithButton from '@/components/SectionTitleLineWithButton.vue'
import { useI18n } from 'vue-i18n'
import { parseMoney } from '@/money-options'
import { useDashboardStore } from '@/stores/dashboard'

const { t } = useI18n()
const dashboardStore = useDashboardStore()

// import SectionBannerStarOnGitHub from '@/components/SectionBannerStarOnGitHub.vue'

// const chartData = ref(null)

// const fillChartData = () => {
//   chartData.value = chartConfig.sampleChartData()
// }

onMounted(async() => {
  await dashboardStore.fetchCostProces()
  // fillChartData()
})

// const mainStore = useMainStore()

const costPrises = computed(() => dashboardStore.costPrices)

// const transactionBarItems = computed(() => mainStore.history)
</script>

<template>
  <LayoutAuthenticated>
    <SectionMain>
      <SectionTitleLineWithButton :icon="mdiChartTimelineVariant" :title="t('dashboard.costpriseTitle')" main>
        <!-- <BaseButton
          href="https://github.com/justboil/admin-one-vue-tailwind"
          target="_blank"
          :icon="mdiGithub"
          label="Star on GitHub"
          color="contrast"
          rounded-full
          small
        /> -->
      </SectionTitleLineWithButton>
      <template v-if="costPrises !== undefined">
        <div class="grid grid-cols-1 gap-6 lg:grid-cols-3 mb-6">
          <CardBoxWidgetCostPrice
            v-if="costPrises.day"
            :trend="costPrises.day.trend"
            :trend-type="costPrises.day.trend_course"
            color="text-emerald-500"
            :infinity="costPrises.day.cost === 'infinity'"
            :icon="mdiWheelBarrow"
            :number="costPrises.day.cost!=='infinity'?costPrises.day.cost:0"
            :label="t('dashboard.costpriseDay')"
          />
          <CardBoxWidgetCostPrice
            v-if="costPrises.week"
            :trend="costPrises.week.trend"
            :trend-type="costPrises.week.trend_course"
            color="text-emerald-500"
            :infinity="costPrises.week.cost === 'infinity'"
            :icon="mdiWheelBarrow"
            :number="costPrises.week.cost!=='infinity'?costPrises.week.cost:0"
            :label="t('dashboard.costpriseWeek')"
          />
          <CardBoxWidgetCostPrice
            v-if="costPrises.month"
            :trend="costPrises.month.trend"
            :trend-type="costPrises.month.trend_course"
            color="text-emerald-500"
            :infinity="costPrises.month.cost === 'infinity'"
            :icon="mdiWheelBarrow"
            :number="costPrises.month.cost!=='infinity'?costPrises.month.cost:0"
            :label="t('dashboard.costpriseMonth')"
          />
          <CardBoxWidgetCostPrice
            v-if="costPrises.quarter"
            :trend="costPrises.quarter.trend"
            :trend-type="costPrises.quarter.trend_course"
            color="text-emerald-500"
            :infinity="costPrises.quarter.cost === 'infinity'"
            :icon="mdiWheelBarrow"
            :number="costPrises.quarter.cost!=='infinity'?costPrises.quarter.cost:0"
            :label="t('dashboard.costpriseQuarter')"
          />
          <CardBoxWidgetCostPrice
            v-if="costPrises.year"
            :trend="costPrises.year.trend"
            :trend-type="costPrises.year.trend_course"
            color="text-emerald-500"
            :infinity="costPrises.year.cost === 'infinity'"
            :icon="mdiWheelBarrow"
            :number="costPrises.year.cost!=='infinity'?costPrises.year.cost:0"
            :label="t('dashboard.costpriseYear')"
          />
        </div>
      </template>


    </SectionMain>
  </LayoutAuthenticated>
</template>
