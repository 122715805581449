<script setup>
import BaseIcon from '@/components/BaseIcon.vue'
import LoaderInComponent from '@/components/LoaderInComponent.vue'
import { mdiRefresh } from '@mdi/js'
import { computed, onBeforeMount, onBeforeUnmount, ref } from 'vue'

import SimpleCABalanceItem from '@/components/CurrencyAccounts/SimpleCaBalance/SimpleCABalanceItem.vue'
import { useAuthStore } from '@/stores/auth_store'
import { useCurrencyAccountsStore } from '@/stores/currencyAccounts'

const currencyAccountsStore = useCurrencyAccountsStore()
const auth = useAuthStore()

const items = computed(() => currencyAccountsStore.currencyAccountsSimple)
const loadingVisible = ref(false)
const timerId = ref(undefined)

onBeforeMount(async () => {
  await handleClickRefresh()

  timerId.value = setInterval(async () => {
    await handleClickRefresh()
  }, 30000)
})
onBeforeUnmount(() => {
  if (timerId.value) {
    clearTimeout(timerId.value)
  }
})
async function handleClickRefresh() {
  if (auth.authToken) {
    loadingVisible.value = true
    await currencyAccountsStore
      .fetchCurrencyAccountsSimple(new URLSearchParams({ only_available: true }))
      .then(() =>
        setTimeout(() => {
          loadingVisible.value = false
        }, 500)
      )
  }
}
</script>

<template>
  <div v-if="items" class="flex items-center justify-start w-full mt-2 md:pl-3 vld-parent">
    <BaseIcon
      :path="mdiRefresh"
      class="cursor-pointer xs:hidden md:flex"
      size="20"
      @click="handleClickRefresh"
    />
    <!-- <div class="flex flex-col justify-center h-full"> -->
    <!-- <div class="grid justify-center h-full grid-cols-3 divide-x"> -->
    <div
      class="flex flex-row justify-start w-full h-full gap-1 pb-2 xs:border-b xs:dark:border-b-borderColor md:border-none"
    >
      <LoaderInComponent class="rounded-xl" :loading-visible="loadingVisible" />
      <div
        v-for="(item, index) in items"
        :key="item.id"
        :class="[
          'flex items-center justify-start xs:pr-3 md:pr-3 flex-1 ',
          index !== items.length - 1 ? 'md:border-r dark:border-r-borderColor' : ''
        ]"
      >
        <SimpleCABalanceItem :item="item" />
      </div>
    </div>
  </div>
</template>
