export default {
    menuAside: {
        menu: 'Menyu',
        dashboard: 'Boshqaruv paneli',
        clients: 'Mijozlar',
        clientsList: "Mijozlar ro'yxati",
        credits: 'Kreditlar',
        rollCalls: "Yo'qlamalar",
        rollCallsActive: 'Aktiv',
        receipts: 'Retseptlar',
        fabrication: 'Ishlab chiqarish',
        doneProducts: 'Mahsulotlar',
        rollCallsHistory: 'Tarix',
        materials: 'Materiallar',
        accounts: 'Hisoblar',
        products: 'Mahsulotlar',
        shareParts: 'Ehtiyot qismlar',
        equipments: 'Uskunalar',
        list: "Ro'yxat",
        categories: 'Kategoriyalar',
        manufacture: 'Ishlab chiqarish',
        incomes: 'Daromadlar',
        expenditures: 'Xarajatlar',
        transfers: "O'tkazmalar",
        currencyAccounts: 'Accounts',
        warehouse: 'Ombor',
        warehouseIncomes: 'Kirimlar',
        warehouseExpenditures: 'Xarajatlar',
        logout: 'Chiqish',
        sales: 'Sotuvlar'
    },
    menuNavBar: {
        myprofile: 'Mening profilim',
        logout: 'Chiqish',
        lightdark: 'Och/Qora',
    },
    somethingWrong: 'Xatolik yuz berdi',
    paginator: '{numPages} dan {currentPageHuman} sahifa',
    cancel: 'Bekor qilish',
    apply: "Qo'shish",
    reset: 'Yangilash',
    selectoption: 'Tanlash',
    selectLabel: 'Tanlash uchun enter ni bosing',
    deselectLabel: "O'chirish uchun enter tugmasini bosing",
    selectedLabel: 'Tanlangan',
    "Nothing's here…": "Bu yerda hech narsa yo'q…",
    'List is empty': "Bo'sh ro'yxat",
    'No elements found': 'Hech nima topilmadi. So\'rovni o\'zgartirib ko\'ring',
    profile: {
        title: 'Profil',
        avatar: 'Avatar',
        avatarHelp: 'Maksimal 500kb',
        upload: 'Yuklash',
        firstname: 'Ism',
        firstnameHelp: 'Majburiy. Sizning ismingiz',
        lastname: 'Familiya',
        lastnameHelp: 'Majburiy. Familiyangiz',
        phone: 'Telefon',
        phoneHelp: 'Sizning telefoningiz',
        submitLabel: 'Yuborish',
        timezone: 'Joriy vaqt mintaqasi',
        timezoneHelp: 'Vaqt mintaqangizni tanlang',
        language: 'Joriy til',
        languageHelp: 'Tilni tanlang',
        currentPassword: 'Joriy parol',
        currentPasswordHelp: 'Majburiy. Sizning joriy parolingiz',
        newPassword: 'Yangi parol',
        newPasswordHelp: 'Majburiy. Yangi parol',
        confirmNewPassword: 'Parolni tasdiqlang',
        confirmNewPasswordHelp: 'Majburiy. Yana bir bor yangi parol',
        usercardHello: 'Salom'
    },
    products: {
        newproduct: 'Yangi mahsulot',
        add: "Qo'shish",
        category: 'Toifa',
        product: 'Mahsulot',
        categoryHelp: 'Toifani tanlang',
        name: 'Ism',
        nameHelp: 'Mahsulot nomini kiriting',
        productlist: "Mahsulotlar ro'yxati",
        addcategory: 'Yangi mahsulot toifasi',
        categoryNameHelp: 'Yangi mahsulot toifasiga nom kiriting',
        categoryList: "Mahsulot toifalarining ro'yxati",
        parentcategory: 'Bosh toifa',
        price: 'Narxi',
        apply: "Qo'llash",
        "There are not enough products in stock": "Omborda yetarlicha mahsulot mavjud emas"
    },
    fabrication: {
        fabrication: "Ishlab chiqarishda",
        fabricate: "Qo'shish",
        titleList: "Ishlab chiqarishda",
        employee: "Xodim",
        receiptAmount: "To'plamlar soni",
        doneproductAmount: "Tayyor mahsulotlar miqdori",
        receipt: "Retseptlar",
        created: "Kun/Vaqt",
        since: 'dan',
        before: 'gacha',
        fabricationTitle: "Ishlab chiqarishda",
        materialNotEnough: "Yetarlicha material mavjud emas",
        needMaterial: 'Kerak'
    },
    shareParts: {
        nomenclature: 'Nomeklatura',
        newsharepart: 'Yangi ehtiyot qism',
        add: "Qo'shish",
        sharepart: 'Ehtiyot qism',
        name: 'Nomi',
        nameHelp: 'Ehtiyot qism nomini kiriting..',
        sharepartlist: 'Ehtiyot qismlar ro\'yxati',
        price: 'Narx',
        apply: 'Qo\'shish',
        sharepartsInWh: 'Ombordagi ehtiyot qismlar',
        amount: 'Miqdori',
        newsharepartincome: 'Yangi kirim',
        income: 'Kirim',
        newsharepartexpenditure: 'Yangi chiqim',
        expenditure: 'Chiqim',
        amountIncome: 'Miqdor',
        amountIncomeHelp: 'Ehtiyot qismlar miqdorini kiriting',
        warehouse: 'Ombor',
        fit: 'Yaroqli',
        unfit: 'Yaroqsiz',
        state: 'Holati',
        updated: 'Yangilandi',
        incomes: 'Kirimlar',
        timestamp: 'Kun/Vaqt',
        expenditures: 'Xarajatlar',
        total: 'Umumiy',
        comment: 'Izoh'
        // "There are not enough products in stock": "Товара на складе не достаточно"
    },
    materials: {
        nomenclature: 'Nomeklatura',
        newmaterial: 'Yangi material',
        add: "Qo'shish",
        material: 'Material',
        name: 'Nomi',
        nameHelp: 'Material nomini kiriting',
        materiallist: "Materiallar ro'yxati",
        price: 'Narxi',
        apply: 'Qabul qilish',
        materialsInWh: 'Ombordagi materiallar',
        amount: 'Miqdori',
        newmaterialincome: 'Yangi kirim',
        income: 'Kirim',
        newmaterialexpenditure: 'Yangi chiqim',
        expenditure: 'Chiqim',
        amountIncome: 'Miqdori',
        amountIncomeHelp: 'Miqdorini kiriting',
        warehouse: 'Ombor',
        fit: 'Yaroqli',
        unfit: 'Yaroqsiz',
        state: 'Holati',
        updated: 'Yangilandi',
        incomes: 'Krimlar',
        timestamp: 'Kun/Vaqt',
        expenditures: 'Xarajatlar',
        total: 'Umumiy'
        // "There are not enough products in stock": "Товара на складе не достаточно"
    },
    equipments: {
        nomenclature: 'Nomeklatura',
        newequipment: 'Yangi uskuna',
        add: 'Qo\'shish',
        equipment: 'Uskuna',
        name: 'Nomi',
        nameHelp: 'Iltimos, Uskuna nomini kiriting',
        equipmentlist: 'Uskunalar ro\'yxati',
        price: 'Narxi',
        apply: 'Qo\'shish',
        equipmentsInWh: 'Ombordagi uskunalar',
        amount: 'Miqdori',
        newequipmentincome: 'Yangi kirim',
        newequipmentexpenditure: 'Yangi chiqim',
        expenditure: 'Chiqim',
        income: 'Kirim',
        amountIncome: 'Soni',
        amountIncomeHelp: 'Uskuna sonini kiriting',
        warehouse: 'Ombor',
        fit: 'Yaroqli',
        unfit: 'Yaroqsiz',
        state: 'Holat',
        updated: 'Yangilandi',
        incomes: 'Kirimlar',
        timestamp: 'Sana',
        expenditures: 'Xarajatlar',
        total: 'Umumiy',
        comment: 'Izoh'
        // "There are not enough products in stock": "Omborda yetarlicha mahsulot yo'q"
    },
    doneProducts: {
        nomenclature: 'Nomeklatura',
        newdoneProduct: 'Yangi mahsulot',
        add: "Qo'shish",
        doneProduct: 'Mahsulotlar',
        name: 'Nomi',
        nameHelp: 'Mahsulot nomini kiriting',
        doneProductlist: 'Mahsulotlar ro\'yxati',
        price: 'narxi',
        apply: 'Qo\'shish',
        doneProductsInWh: 'Ombordagi mahsulotlar',
        amount: 'Soni',
        newdoneProductincome: 'Yangi kirim',
        newdoneProductexpenditure: 'Yangi chiqim',
        expenditure: 'Chiqim',
        income: 'Kirim',
        amountIncome: 'Soni',
        amountIncomeHelp: 'Miqdorini kiriting',
        warehouse: 'Ombor',
        fit: 'Yaroqli',
        unfit: 'Yaroqsiz',
        state: 'Holati',
        updated: 'Yangilandi',
        incomes: 'Kirimlar',
        timestamp: 'Kun/Vaqt',
        expenditures: 'Xarajatlar',
        total: 'Umumiy'
        // "There are not enough products in stock": "Товара на складе не достаточно"
    },
    manufacture: {
        carAmount: 'Avtomobil narxi',
        user: 'Foydalanuvchi',
        timestamp: 'Kun/Vaqt',
        viewItems: "Elementlarni ko'rish",
        itemName: 'Element nomi',
        amount: 'Narxi',
        newCarIncome: 'Yangi avtomobil daromadi',
        add: "Qo'shish",
        carConfiguration: 'Avtomobil konfiguratsiyasi',
        carConfigurationHelp: 'konfiguratsiyani tanlang',
        amountHelp: 'Narxini kiriting',
        itemsInIncomeTitle: 'Daromaddagi elementlar',
        incomes: 'Daromadlar',
        addIncome: "Daromad qo'shish",
        expenditures: 'Xarajatlar',
        expenditure: 'Xarajatlar',
        date: 'Sana'
    },
    clients: {
        newClient: 'Yangi mijoz',
        add: "Qo'shish",
        firstName: 'Ismi',
        firstNameHelp: 'Ismini kiriting',
        lastName: 'Familiyasi',
        lastNameHelp: 'Familiyasini kiriting',
        phone: 'Telefon raqam',
        phoneHelp: 'Telefon raqamni kiriting',
        clientsTitle: 'Mijozlar',
        addClient: "Mijoz qo'shish",
        fullname: 'Ismi',
        company: 'Kompaniya',
        spent: 'Sarflangan',
        newClientCurrencyAccount: 'Mijozning yangi valyuta hisobi',
        changeClient: "O'zgartirish",
        companyHelp: "Kompaniya nomini kiriting",
        change: "O'zgartirish",
        client: 'Mijoz',
        clientHelp: 'Mijozni tanlang',
        currency: 'Valyuta',
        currencyHelp: 'Valyutani tanlang',
        clientsCreditsTitle: 'Mijoz kreditlari',
        duplicateCCAError: 'Ushbu valyuta bilan mijozning valyuta hisobini takrorlash',
        newClientCreditLine: 'Yangi mijoz kredit liniyasi',
        creditLineAmount: 'Miqdori',
        creditLineAmountHelp: 'Kredit liniyasi miqdorini kiriting',
        clientsCreditLinesTitle: 'Mijozning kredit liniyalari',
        addLine: "Liniya qo'shish",
        open: 'Ochish',
        isClose: 'Yopilganmi?',
        isOpen: 'Ochiq',
        clientName: 'Mijozning ismi',
        credits: 'Kreditlar',
        credit: 'Kredit',
        debt: 'Qarz',
        loans: 'Kredit',
        clientsCurrencyAccounts: 'Mijozlarning valyuta hisoblari',
        addAccount: "Hisob qo'shish",
        balanceIsNotEnough: 'Balans yetarli emas',
        newMoneyTransfer: "Yangi pul o'tkazma",
        newMoneyIncome: 'Yangi pul daromadlari',
        newMoneyExpenditure: 'Yangi pul xarajatlari',
        debitAmount: 'Debet miqdori',
        debitAmountHelp: "Iltimos, Debit miqdorini kiriting",
        receiptCurrencyAccount: 'Valyuta tushumlari hisobi',
        receiptCurrencyAccountHelp: 'Iltimos, qabul qiluvchi valyutadagi hisobni tanlang',
        balance: 'Balans',
        amount: 'Miqdor',
        amountHelp: 'Miqdorni kiriting',
        receiptAmount: 'Qabul qilingan miqdor',
        receiptAmountHelp: 'Qabul qilingan miqdorni kiriting',
        clientCurrencyAccountTitle: 'Mijozning valyuta hisobi',
        moneyTransfers: "Pul o'tkazmalari",
        moneyIncomes: 'Pul daromadlari',
        moneyExpenditures: 'Pul xarajatlari',
        receipt: 'Kvitansiya',
        debit: 'Debet',
        timestamp: 'Vaqt belgisi',
        user: 'Foydalanuvchi',
        debitor: 'Qarzdor',
        recipient: 'Qabul qiluvchi',
        opponent: 'Qarzdor/Qabul qiluvchi',
        newCreditRepayment: "Yangi kredit to'lovi",
        currencyAccount: 'Valyuta hisobi',
        currencyAccountToPay: 'Kredit hisobi',
        currencyAccountToPayHelp: 'Kredit hisobini tanlang',
        "Please choose currency account in client currency": 'Iltimos, mijoz valyutasida valyuta hisobini tanlang',
        "Please enter repayment amount": "Iltimos, to'lov miqdorini kiriting",
        "Exchange rate": "Ayirboshlash kursi",
        exchangeRateHelp: "Iltimos, ayirboshlash kursini kiriting. To'lov miqdori",
        "Client credit line": "Mijoz kredit liniyasi",
        "in": "ichida",
        addRepayment: "To'lovni qo'shish",
        "Credit line is closed": "Kredit liniyasi yopildi",
        "Credit line amount is not enogh": "Kredit liniyasining miqdori yetarlicha emas",
        "ClientDoesnotExists": "Mijoz mavjud emas",
        comment: 'Izoh'
    },
    cart: {
        addToCart: "Yangi maydon qo'shish",
        price: "Narx",
        amount: "Miqdori",
        total: "Umumiy",
        ok: "Ok",
        removeFromCart: "O'chirish",
        add: "Qo'shish",
        view: "Ko'rish",
        cartTitle: 'Maydon',
        name: 'Nomi',
        clearCart: 'Tozalash',
        clearCartTitle: 'Maydonni tozalash',
        clearCartBody: 'Siz haqiqatdan ham maydonni tozalamoqchimisiz?',
        pay: "To'lov",
        kassa: 'Naqt',
        credit: 'Kredit',
        prepayment: "Oldindan to'lov",
        paymentType: "To'lov turi",
        cash: 'Naqt',
        noncash: 'Naqtsiz',
        currencyAccount: 'Hozirki hisobdan',
        incomeCurrencyAccount: 'Kirim hisobidan',
        notEnough: "Omborda yetarlicha mahsulot yo'q",
        "ProductsDoesNotExists": "Mahsulot mavjud emas",
        available: 'Mavjud'
    },
    currencyAccounts: {
        client: 'Mijoz',
        account: 'Hisob',
        "CurrencyAccounDoesnotExists": "Hisob mavjud emas",
        accountHelp: 'Kerakli hisobni tanlang',
        title: 'Hisoblar',
        name: 'Nomi',
        balance: 'Balans',
        accountType: 'Hisob turi',
        accountTypeHelp: 'Iltimos, Hisobni tanlang',
        cash: 'Naqt',
        noncash: 'Naqtsiz',
        view: "Ko'rish",
        newCurrencyAccount: 'Yangi hisob',
        add: "Qo'shish",
        currency: "Valyuta",
        currencyHelp: 'Valyutani tanlang',
        moneyTransfers: "Pul o'tkazmalari",
        moneyIncomes: 'Pul daromadlari',
        moneyExpenditures: 'Pul xarajatlari',
        receipt: 'Kvitansiya',
        debit: 'Debet',
        timestamp: 'Vaqt belgisi',
        user: 'Foydalanuvchi',
        debitor: 'Qarzdor',
        recipient: 'Qabul qiluvchi',
        opponent: 'Qarzdor/Qabul qiluvchi',
        addAccount: "Hisob qo'shish",
        balanceIsNotEnough: 'Balans yetarli emas',
        newMoneyTransfer: "Yangi pul o'tkazma",
        newMoneyIncome: 'Yangi pul daromadlari',
        newMoneyExpenditure: 'Yangi pul xarajatlari',
        newFinanceExpenditure: 'Yangi xarajat',
        newWithdraw: 'New Withdraw ',
        debitAmount: 'Debet miqdori',
        debitAmountHelp: 'Iltimos, debet miqdorini kiriting',
        receiptCurrencyAccount: 'Valyuta tushumlari hisobi',
        receiptCurrencyAccountHelp: 'Iltimos, qabul qiluvchi valyutadagi hisobni tanlang',
        amount: 'Miqdor',
        amountHelp: 'Miqdorni kiriting',
        receiptAmount: 'Qabul qilingan miqdor',
        receiptAmountHelp: 'Qabul qilingan miqdorni kiriting',
        transfer: "Transfer",
        income: "Kirim",
        expenditure: "Xarajatlar",
        transfers: "O'tkazmalar",
        receipts: 'Kvitansiyalar',
        incomes: 'Daromad',
        withdrawals: 'Chiqim',
        expenditures: 'Xarajatlar',
        category: 'Kategoriya',
        comment: 'Izoh',
        newFinanceExpenditureCategory: 'Yangi kategoriya',
        sale: 'Savdo',
        prepayment: "Oldindan to'lov",
        debt: 'Qarz'
    },
    warehouse: {
        productlist: "Ombordagi mahsulotlar ro'yxati",
        productIncomelist: "Kirimlar ro'yxati",
        productExpenditurelist: "Chiqimlar ro'yxati",
        receivingUser: 'Qabul qiluvchi',
        submittingUser: 'Yuboruvchi'
    },
    rollCalls: {
        EnterMessage: 'Xabaringizni kiriting',
        ok: "Ok",
        start: "Boshlash",
        expires: "Davomiyligi",
        tooFarFromTheOfficeError: 'Siz ofisdan juda uzoqdasiz',
        question: 'Savol',
        answerState: 'Javob',
        timestamp: 'Kun/Vaqt',
        history: 'Tarix',
        locationRequired: 'Joylashuv talab qilinadi'
    },
    sales: {
        titleList: "Sotuvlar",
        paymentType: "To'lov turi",
        client: "Mijoz",
        currencyAccount: "Hozirki hisob",
        total: 'Umumiy',
        employee: 'Xodim',
        created: 'Yaratilgan',
        since: 'dan',
        before: 'gacha',
        saleTitle: 'Sotish',
        timestamp: 'Vaqti',
        clientCAState: 'Mijoz hisobining holati',
        currencyAccountState: "Kompaniya hisobining o'zgarishi"
    },
    receipts: {
        titleList: "Retseptlar",
        name: "Nomi",
        nameHelp: "Retsept nomini kiriting",
        receipt: "Retsept",
        doneproduct: "Mahsulot",
        doneproductAmount: "Tayyor mahsulotlar miqdori",
        newReceipt: "Yangi retsept",
        create: 'Yaratish',
        material: 'Material',
        materialAmount: 'Miqdori',
        edit: 'O\'zgartirish',
        receiptDuplicate: 'Bu nomdagi retsept allaqachon yaratilgan'
    },
    dashboard: {
        costpriseTitle: 'Себестоимость',
        costpriseDay: 'День',
        costpriseWeek: 'Неделя',
        costpriseMonth: 'Месяц',
        costpriseQuarter: 'Квартал',
        costpriseYear: 'Год',
    }
}