export default [
    {
        meta: {
            title: 'Sales',
            permissions: "sales"

        },
        path: '',
        name: 'sales',
        component: () => import('@/views/sales/SalesListView.vue')
    },
    {
        meta: {
            title: 'Sales',
            permissions: "sales"

        },
        path: ':id',
        name: 'sale',
        component: () => import('@/views/sales/SaleView.vue')
    },
]