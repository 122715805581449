export default [
    {
        meta: {
            title: 'Done Products',
            permissions: "doneproducts"

        },
        path: '',
        name: 'doneProducts-list',
        component: () => import('@/views/doneProducts/DoneProductsListView.vue')
    },
    {
        meta: {
            title: 'Done Products',
            permissions: "doneproducts"

        },
        path: 'warehouse',
        name: 'doneProducts-warehouse',
        component: () => import('@/views/doneProducts/DoneProductsWarehouseView.vue')
    },
    {
        meta: {
            title: 'Done Products',
            permissions: "doneproducts"

        },
        path: 'warehouse/:id/',
        name: 'doneProduct-in-warehouse',
        component: () => import('@/views/doneProducts/DoneProductInWarehouseView.vue')
    }
]