import { defineStore } from 'pinia';

import { fetchWrapper } from '@/helpers/fetch-wrapper';
import { useUserStore } from '@/stores/user_store.js'

import router from '@/router'

const baseUrl = `${import.meta.env.VITE_BASE_URL}`;

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        // user: JSON.parse(localStorage.getItem('user')),
        authToken: JSON.parse(localStorage.getItem('authToken')),
        returnUrl: null
    }),
    actions: {
        async login(username, password) {
            const authToken = await fetchWrapper.post(`${baseUrl}/auth/token/`, { username, password });
            // const user = await fetchWrapper.post(`${baseUrl}/authenticate`, { username, password });

            // update pinia state
            // this.user = user;
            this.authToken = authToken;

            // store user details and jwt in local storage to keep user logged in between page refreshes
            // localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('authToken', JSON.stringify(authToken));
            await this.testToken()
            // redirect to previous url or default to home page
            router.push(this.returnUrl || '/');
        },
        logout() {
            // this.user = null;
            localStorage.removeItem('user');
            localStorage.removeItem('authToken');
            router.push('/login');
        },
        async testToken() {
            const userStore = useUserStore()
            if (this.authToken === null) {
                const returnUrl = window.location.pathname + window.location.search + window.location.hash
                this.returnUrl = returnUrl !== '/login' ? returnUrl : '/'
                router.push('/login')
            }
            let user;
            await fetchWrapper.get(`${baseUrl}/auth/users/me/`).then(async (user) => {
                userStore.setUser(user)
            }).catch(async (error) => {
                console.log('error', error);
                const result = await this.tryRefreshToken()
                if (result) {
                    user = await fetchWrapper.get(`${baseUrl}/auth/users/me/`).catch(async () => {
                        router.push('/login')
                    })
                } else {
                    router.push('/login')
                }
            });
            return user
        },
        async tryRefreshToken() {
            if (this.authToken === null) {
                router.push('/login')
                return false
            }
            const refresh = this.authToken.refresh;
            const { access } = await fetchWrapper.post(`${baseUrl}/auth/token/refresh/`, { refresh }).catch(() => {
                this.logout()
                router.push('/login')
                return false
            }
            );
            localStorage.setItem('authToken', JSON.stringify({ refresh, access }));
            this.authToken.access = access
            return true
        },
        async register(username, password) {
            return new Promise((resolve, reject) => {
                fetchWrapper.post(`${baseUrl}/auth/users/`, { username, password }).then(() => {
                    resolve('ok')
                }).catch((error) => {
                    reject(error)
                });
            }
            )
        },
    }
});