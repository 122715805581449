import { defineStore } from 'pinia'
import { fetchWrapper } from '@/helpers/fetch-wrapper';
import { useUserStore } from '@/stores/user_store';
import { ref } from 'vue'

const baseUrl = `${import.meta.env.VITE_BASE_URL}`;


export const useClientsStore = defineStore('clients', () => {
  const clients = ref([])
  const clientsCurrencyAccounts = ref([])
  const clientsCurrencyAccount = ref(undefined)

  const clientsCredits = ref([])
  const clientsCreditLines = ref([])
  const clientsCreditLine = ref(undefined)

  async function fetchSampleClients(query = null) {
    let url = new URL(`${baseUrl}/clients/clients/`)
    url.searchParams.set('limit', '100000')
    if (query != null) {
      try {
        query.forEach((value, key) => url.searchParams.set(key, value))
      } catch (err) {
        console.log(err)
      }
    }
    await fetchWrapper.get(new String(url)).then((result) => {
      const results = result?.results
      // if (results.length) {
      clients.value = results
      // }
    })
    // .catch((error) => {
    //   alert(error.message)
    // });
  }
  async function addNewClient(client) {
    return new Promise((resolve, reject) => {
      fetchWrapper.post(`${baseUrl}/clients/clients/`, client).then((client) => {
        const results = [client, ...clients.value]
        clients.value = results
        resolve('ok')
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }

  async function fetchClientsCurrencyAccounts(query = null) {
    let url = new URL(`${baseUrl}/client-currency-accounts/client-currency-accounts/`)
    url.searchParams.set('limit', '100000')
    if (query != null) {
      try {
        query.forEach((value, key) => url.searchParams.set(key, value))
      } catch (err) {
        console.log(err)
      }
    }
    await fetchWrapper.get(new String(url)).then((result) => {
      const results = result?.results
      // if (results.length) {
      clientsCurrencyAccounts.value = results
      // }
    })
    // .catch((error) => {
    //   alert(error.message)
    // });
  }
  async function fetchClientsCurrencyAccount(accountId) {
    await fetchWrapper.get(`${baseUrl}/client-currency-accounts/client-currency-accounts/${accountId}`).then((result) => {
      if (result) {
        clientsCurrencyAccount.value = result
      }
    })
    // .catch((error) => {
    //   alert(error.message)
    // });
  }
  async function addNewClientCurrencyAccount(account) {
    return new Promise((resolve, reject) => {
      fetchWrapper.post(`${baseUrl}/client-currency-accounts/client-currency-accounts/`, account).then((account) => {
        const results = [account, ...clientsCurrencyAccounts.value]
        clientsCurrencyAccounts.value = results
        resolve('ok')
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }
  async function fetchClientsPrepayments() {
    return new Promise((resolve, reject) => {
      fetchWrapper.get(`${baseUrl}/client-currency-accounts/client-currency-accounts/get_prepayments/`).then((result) => {
        resolve(result)
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }
  async function fetchClientsDebts() {
    return new Promise((resolve, reject) => {
      fetchWrapper.get(`${baseUrl}/client-currency-accounts/client-currency-accounts/get_debts/`).then((result) => {
        resolve(result)
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }
  async function fetchClientsPrepaymentsAndDebts() {
    return new Promise((resolve, reject) => {
      fetchWrapper.get(`${baseUrl}/client-currency-accounts/client-currency-accounts/get_prepayments_and_debts/`).then((result) => {
        resolve(result)
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }
  async function fetchClientsCredits() {
    await fetchWrapper.get(`${baseUrl}/client-credits/client-credits/?limit=100000`).then((result) => {
      const results = result?.results
      if (results.length) {
        clientsCredits.value = results
      }
    })
    // .catch((error) => {
    //   alert(error.message)
    // });
  }

  async function fetchClientCreditLines(query = null) {
    let url = new URL(`${baseUrl}/client-credits/client-credit-lines/`)
    url.searchParams.set('limit', '100000')
    if (query != null) {
      try {
        query.forEach((value, key) => url.searchParams.set(key, value))
      } catch (err) {
        console.log(err)
      }
    }

    await fetchWrapper.get(new String(url)).then((result) => {
      const results = result?.results
      // if (results.length) {
      clientsCreditLines.value = results
      // }
    })
    // .catch((error) => {
    //   alert(error.message)
    // });
  }

  async function addNewClientCreditLine(line) {
    return new Promise((resolve, reject) => {
      fetchWrapper.post(`${baseUrl}/client-credits/client-credit-lines/`, line).then((line) => {
        const results = [line, ...clientsCreditLines.value]
        clientsCreditLines.value = results
        resolve('ok')
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }
  async function fetchClientCreditLine(id) {
    await fetchWrapper.get(`${baseUrl}/client-credits/client-credit-lines/${id}/`).then((result) => {
      if (result) {
        clientsCreditLine.value = result
      }
    })
    // .catch((error) => {
    //   alert(error.message)
    // });
  }
  async function addNewClientCreditLoan(repayment) {
    return new Promise((resolve, reject) => {
      const userStore = useUserStore()
      fetchWrapper.post(`${baseUrl}/client-credits/client-credit-loanrepayments/`, repayment).then((repayment) => {
        const result = clientsCreditLine.value
        repayment.user = { 'last_name': userStore.$state.lastName, 'first_name': userStore.$state.firstName }
        const creditIndex = result.credits.findIndex(credit => credit.id === repayment.client_credit.id)
        result.credits[creditIndex].repayments.unshift(repayment)
        result.credits[creditIndex].loans_sum = parseFloat(result.credits[creditIndex].loans_sum) + parseFloat(repayment.amount)
        clientsCreditLine.value = result
        resolve('ok')
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }
  async function fetchClientMoneyTransfers(currency_account_id) {
    return new Promise((resolve, reject) => {
      fetchWrapper.get(`${baseUrl}/client-currency-accounts/money-transfers/?currency_account_id=${currency_account_id}&limit=100000`).then((result) => {
        const results = result?.results
        if (results.length) {
          resolve(results)
        } else { resolve([]) }
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }
  async function addNewClientMoneyTransfer(transfer) {
    return new Promise((resolve, reject) => {
      fetchWrapper.post(`${baseUrl}/client-currency-accounts/money-transfers/`, transfer).then((transfer) => {
        const results = clientsCurrencyAccounts.value
        results.forEach((item) => {
          if (item.id == transfer.debit_currency_account.id) {
            item.balance = parseFloat(item.balance) - parseFloat(transfer.debit_amount)
          } else if (item.id == transfer.receipt_currency_account.id) {
            item.balance = parseFloat(item.balance) + parseFloat(transfer.receipt_amount)
          }
        })
        clientsCurrencyAccounts.value = results
        resolve(transfer)
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }
  async function fetchClientMoneyIncomes(currency_account_id) {
    return new Promise((resolve, reject) => {
      fetchWrapper.get(`${baseUrl}/client-currency-accounts/money-incomes/?currency_account_id=${currency_account_id}&limit=100000`).then((result) => {
        const results = result?.results
        if (results.length) {
          resolve(results)
        } else { resolve([]) }
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }
  async function addNewClientMoneyIncome(income) {
    return new Promise((resolve, reject) => {
      fetchWrapper.post(`${baseUrl}/client-currency-accounts/money-incomes/`, income).then((income) => {
        const results = clientsCurrencyAccounts.value
        results.forEach((item) => {
          if (item.id == income.currency_account.id) {
            item.balance = parseFloat(item.balance) + parseFloat(income.amount)
            item.usd_balance = parseFloat(item.usd_balance) + parseFloat(income.usd_amount)
          }
        })
        clientsCurrencyAccounts.value = results
        resolve(income)
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }
  async function fetchClientMoneyExpenditures(currency_account_id) {
    return new Promise((resolve, reject) => {
      fetchWrapper.get(`${baseUrl}/client-currency-accounts/money-expenditures/?currency_account_id=${currency_account_id}&limit=100000`).then((result) => {
        const results = result?.results
        if (results.length) {
          resolve(results)
        } else { resolve([]) }
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }
  async function addNewClientMoneyExpenditure(expenditure) {
    return new Promise((resolve, reject) => {
      fetchWrapper.post(`${baseUrl}/client-currency-accounts/money-expenditures/`, expenditure).then((expenditure) => {
        const results = clientsCurrencyAccounts.value
        results.forEach((item) => {
          if (item.id == expenditure.currency_account.id) {
            item.balance = parseFloat(item.balance) - parseFloat(expenditure.amount)
          }
        })
        clientsCurrencyAccounts.value = results
        resolve(expenditure)
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }
  async function changeClient(client, clientId) {
    return new Promise((resolve, reject) => {
      fetchWrapper.patch(`${baseUrl}/clients/clients/${clientId}/`, client).then((client) => {
        let newclients = []
        clients.value.forEach(oldItem => {
          if (oldItem.id === clientId) {
            newclients.push(client)
          } else {
            newclients.push(oldItem)
          }
        })
        clients.value = newclients
        resolve('ok')
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }
  return {
    clients,
    clientsCurrencyAccounts,
    clientsCredits,
    clientsCreditLines,
    clientsCreditLine,
    clientsCurrencyAccount,
    fetchSampleClients,
    addNewClient,
    fetchClientsCurrencyAccounts,
    fetchClientsCurrencyAccount,
    addNewClientCurrencyAccount,
    fetchClientsCredits,
    fetchClientCreditLines,
    addNewClientCreditLine,
    fetchClientCreditLine,
    addNewClientCreditLoan,
    fetchClientMoneyTransfers,
    addNewClientMoneyTransfer,
    fetchClientMoneyIncomes,
    addNewClientMoneyIncome,
    fetchClientMoneyExpenditures,
    addNewClientMoneyExpenditure,
    changeClient,
    fetchClientsPrepayments,
    fetchClientsDebts,
    fetchClientsPrepaymentsAndDebts
  }
})
