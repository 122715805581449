export default {
    menuAside: {
        menu: 'Menu',
        dashboard: 'Dashboard',
        clients: 'Clients',
        clientsList: 'Clients list',
        credits: 'Credits',
        rollCalls: 'Roll Calls',
        rollCallsActive: 'Active',
        receipts: 'Recipe',
        fabrication: 'Production',
        doneProducts: 'Products',
        rollCallsHistory: 'History',
        materials: 'Materials',
        accounts: 'Accounts',
        products: 'Products',
        shareParts: 'Share Parts',
        equipments: 'Equipments',
        list: 'List',
        categories: 'Categories',
        manufacture: 'Manufacture',
        incomes: 'Incomes',
        transfers: 'Transfers',
        expenditures: 'Expenditures',
        currencyAccounts: 'Accounts',
        warehouse: 'Warehouse',
        warehouseIncomes: 'Incomes',
        warehouseExpenditures: 'Expenditures',
        logout: 'Log out',
        sales: 'Sales'
    },
    menuNavBar: {
        myprofile: 'My Profile',
        logout: 'Log out',
        lightdark: 'Light/Dark',
    },
    somethingWrong: 'Something wrong',
    paginator: 'Page {currentPageHuman} of {numPages}',
    cancel: 'Cancel',
    apply: 'Apply',
    reset: 'Reset',
    selectoption: 'Select one',
    selectLabel: 'Press enter to select',
    deselectLabel: 'Press enter to remove',
    selectedLabel: 'Selected',
    "Nothing's here…": "Nothing's here…",
    'List is empty': 'List is empty',
    'No elements found': 'No elements found. Consider changing the search query',
    profile: {
        title: 'Profile',
        avatar: 'Avatar',
        avatarHelp: 'Max 500kb',
        upload: 'Upload',
        firstname: 'First name',
        firstnameHelp: 'Required. Your first name',
        lastname: 'Last name',
        lastnameHelp: 'Required. Your last name',
        phone: 'Phone',
        phoneHelp: 'Your phone',
        submitLabel: 'Submit',
        timezone: 'Current timezone',
        timezoneHelp: 'Choose your timezone',
        language: 'Current language',
        languageHelp: 'Choose your language',
        currentPassword: 'Current password',
        currentPasswordHelp: 'Required. Your current password',
        newPassword: 'New password',
        newPasswordHelp: 'Required. New password',
        confirmNewPassword: 'Confirm password',
        confirmNewPasswordHelp: 'Required. New password one more time',
        usercardHello: 'Hello'
    },
    products: {
        newproduct: 'New product',
        add: 'Add',
        category: 'Category',
        product: 'Product',
        categoryHelp: 'Please choose category',
        name: 'Name',
        nameHelp: 'Please enter product name',
        productlist: 'Products list',
        addcategory: 'New product category',
        categoryNameHelp: 'Please enter product category name',
        categoryList: 'Products categories list',
        parentcategory: 'Parent category',
        price: 'Price',
        apply: 'Apply',
        "There are not enough products in stock": "There are not enough products in stock"
    },
    fabrication: {
        fabrication: "Fabrication",
        fabricate: "Fabricate",
        titleList: "Manufacturing of products",
        employee: "Employee",
        receiptAmount: "Number of parties",
        doneproductAmount: "Quantity of finished products",
        receipt: "Recipe",
        created: "Producted",
        since: 'from',
        before: 'to',
        fabricationTitle: "Manufacturing of products",
        materialNotEnough: "Not enough material",
        needMaterial: 'Necessary'
    },
    shareParts: {
        nomenclature: 'Nomenclature',
        newsharepart: 'New share part',
        add: 'Add',
        sharepart: 'Share part',
        name: 'Name',
        nameHelp: 'Please enter share part name',
        sharepartlist: 'Share parts list',
        price: 'Price',
        apply: 'Apply',
        sharepartsInWh: 'Share parts in warehouse',
        amount: 'Amount',
        newsharepartincome: 'New income',
        income: 'Income',
        newsharepartexpenditure: 'New expense',
        expenditure: 'Expense',
        amountIncome: 'Quantity',
        amountIncomeHelp: 'Enter quantity',
        warehouse: 'Warehouse',
        fit: 'Fit',
        unfit: 'Unfit',
        state: 'Status',
        updated: 'Updated',
        incomes: 'Incomes',
        timestamp: 'Date/Time',
        expenditures: 'Expenditures',
        total: 'Total',
        comment: 'Comment'
        // "There are not enough products in stock": "Товара на складе не достаточно"
    },
    materials: {
        nomenclature: 'Nomenclature',
        newmaterial: 'New material',
        add: 'Add',
        material: 'Material',
        name: 'Name',
        nameHelp: 'Please enter materials name',
        materiallist: 'Material list',
        price: 'Price',
        apply: 'Apply',
        materialsInWh: 'Materials in warehouse',
        amount: 'Amount',
        newmaterialincome: 'New income',
        income: 'Income',
        newmaterialexpenditure: 'New expence',
        expenditure: 'Expence',
        amountIncome: 'Quantity',
        amountIncomeHelp: 'Enter quantity',
        warehouse: 'Warehouse',
        fit: 'Fit',
        unfit: 'Unfit',
        state: 'Status',
        updated: 'Updated',
        incomes: 'Incomes',
        timestamp: 'Date/Time',
        expenditures: 'Expenditures',
        total: 'Total'
        // "There are not enough products in stock": "Товара на складе не достаточно"
    },
    equipments: {
        nomenclature: 'Nomenclature',
        newequipment: 'New equipment',
        add: 'Add',
        equipment: 'Equipment',
        name: 'Name',
        nameHelp: 'Please enter equipment name',
        equipmentlist: 'Equipment list',
        price: 'Price',
        apply: 'Apply',
        equipmentsInWh: 'Equipments in warehouse',
        amount: 'Amount',
        newequipmentincome: 'New income',
        income: 'Income',
        newequipmentexpenditure: 'New Expence',
        expenditure: 'Expence',
        amountIncome: 'Quantity',
        amountIncomeHelp: 'Enter quantity',
        warehouse: 'Warehouse',
        fit: 'Fit',
        unfit: 'Unfit',
        state: 'State',
        updated: 'Updated',
        incomes: 'Incomes',
        timestamp: 'Date/Time',
        expenditures: 'Expenditures',
        total: 'Total',
        comment: 'Comment'
        // "There are not enough products in stock": "Товара на складе не достаточно"
    },
    doneProducts: {
        nomenclature: 'Nomenclature',
        newdoneProduct: 'New Product',
        add: 'Add',
        doneProduct: 'Products',
        name: 'Name',
        nameHelp: 'Please enter product name',
        doneProductlist: 'Products list',
        price: 'Price',
        apply: 'Apply',
        doneProductsInWh: 'Products in warehouse',
        amount: 'Amount',
        newdoneProductincome: 'New income',
        newdoneProductexpenditure: 'New expence',
        expenditure: 'Expence',
        income: 'Income',
        amountIncome: 'Quantity',
        amountIncomeHelp: 'Enter quantity',
        warehouse: 'Warehouse',
        fit: 'Fit',
        unfit: 'Unfit',
        state: 'Status',
        updated: 'Updates',
        incomes: 'Incomes',
        timestamp: 'Date/Time',
        expenditures: 'Expenditures',
        total: 'Total'
        // "There are not enough products in stock": "Товара на складе не достаточно"
    },
    manufacture: {
        carAmount: 'Car amount',
        user: 'User',
        timestamp: 'Date/Time',
        viewItems: 'View items',
        itemName: 'Item name',
        amount: 'Amount',
        newCarIncome: 'New car income',
        add: 'Add',
        carConfiguration: 'Car configuration',
        carConfigurationHelp: 'Please choose configuration',
        amountHelp: 'Please enter amount',
        itemsInIncomeTitle: 'Items in income',
        incomes: 'Incomes',
        addIncome: 'Add income',
        expenditures: 'Expenditures',
        expenditure: 'Expenditure',
        date: 'Date'
    },
    clients: {
        newClient: 'New client',
        add: 'Add',
        firstName: 'First name',
        firstNameHelp: 'Please enter first name',
        lastName: 'Last name',
        lastNameHelp: 'Please enter last name',
        phone: 'Phone',
        phoneHelp: 'Please enter phone number',
        clientsTitle: 'Clients',
        addClient: 'Add client',
        fullname: 'Name',
        company: 'Company',
        spent: 'Spent',
        changeClient: 'Change',
        companyHelp: 'Please enter company name',
        change: 'Change',
        newClientCurrencyAccount: 'New client currency account',
        client: 'Client',
        clientHelp: 'Please choose client',
        currency: 'Currency',
        currencyHelp: 'Please choose currency',
        clientsCreditsTitle: 'Clients credits',
        duplicateCCAError: 'Duplicate client currency account with this currency',
        newClientCreditLine: 'New client credit line',
        creditLineAmount: 'Amount',
        creditLineAmountHelp: 'Please enter credit line amount',
        clientsCreditLinesTitle: 'Clients credit lines',
        addLine: 'Add line',
        open: 'Open',
        isClose: 'Is close',
        isOpen: 'Is open',
        clientName: 'Client Name',
        credits: 'Credits',
        credit: 'Credit',
        debt: 'Debt',
        loans: 'Loans',
        clientsCurrencyAccounts: 'Clients currency accounts',
        addAccount: 'Add account',
        balanceIsNotEnough: 'The balance is not enough',
        newMoneyTransfer: 'New money transfer',
        newMoneyIncome: 'New money income',
        newMoneyExpenditure: 'New money expenditure',
        debitAmount: 'Debit amount',
        debitAmountHelp: 'Please enter debit amount',
        receiptCurrencyAccount: 'Receipt currency account',
        receiptCurrencyAccountHelp: 'Please choose receipt currency account',
        balance: 'Balance',
        amount: 'Amount',
        amountHelp: 'Please enter amount',
        receiptAmount: 'Receipt amount',
        receiptAmountHelp: 'Please enter receipt amount',
        clientCurrencyAccountTitle: 'Client currency account',
        moneyTransfers: 'Money Transfers',
        moneyIncomes: 'Money Incomes',
        moneyExpenditures: 'Money Expenditures',
        receipt: 'Receipt',
        debit: 'Debit',
        timestamp: 'Date/Time',
        user: 'User',
        debitor: 'Debitor',
        recipient: 'Recipient',
        opponent: 'Debitor/Recipient',
        newCreditRepayment: 'New credit repayment',
        currencyAccount: 'Currency Account',
        currencyAccountToPay: 'Enrollment account',
        currencyAccountToPayHelp: 'Choose enrollment account',
        "Please choose currency account in client currency": 'Please choose currency account in client currency',
        "Please enter repayment amount": "Please enter repayment amount",
        "Exchange rate": "Exchange rate",
        exchangeRateHelp: "Please enter exchange rate. Amount to repayment",
        "Client credit line": "Client credit line",
        "in": "in",
        addRepayment: 'Add repayment',
        "Credit line is closed": "Credit line is closed",
        "Credit line amount is not enogh": "Credit line amount is not enogh",
        "ClientDoesnotExists": "Client doesn't exists",
        comment: 'Comment'
    },
    cart: {
        addToCart: "Add to cart",
        price: "Price",
        amount: "Amount",
        total: "Total",
        ok: "Ok",
        removeFromCart: "Remove",
        add: 'Add',
        view: 'View',
        cartTitle: 'Cart',
        name: 'Name',
        clearCart: 'Clear',
        clearCartTitle: 'Clear cart',
        clearCartBody: 'Do you want to clear the cart?',
        pay: 'Pay',
        kassa: 'Cash',
        credit: 'Credit',
        prepayment: 'Prepayment',
        paymentType: 'Payment type',
        cash: 'Cash',
        noncash: 'Non-cash',
        currencyAccount: 'From currency account',
        incomeCurrencyAccount: 'Income currency account',
        notEnough: "There are not enough items in the warehouse",
        "ProductsDoesNotExists": "Products doesn't exists",
        available: 'Available'
    },
    currencyAccounts: {
        client: 'Client',
        account: 'Currency account',
        accountHelp: 'Please choose currency account',
        "CurrencyAccounDoesnotExists": "CurrencyAccount doesn't exists",
        title: 'Currency accounts',
        name: 'Name',
        currency: 'Currency',
        accountType: 'Account Type',
        accountTypeHelp: 'Please choose account type',
        cash: 'Cash',
        noncash: 'Non-Cash',
        balance: 'Balance',
        view: 'View',
        newCurrencyAccount: 'New currency account',
        add: 'Add',
        currencyHelp: 'Please choose currency',
        moneyTransfers: 'Money Transfers',
        moneyIncomes: 'Money Incomes',
        moneyExpenditures: 'Money Expenditures',
        receipt: 'Receipt',
        debit: 'Debit',
        timestamp: 'Date/Time',
        user: 'User',
        debitor: 'Debitor',
        recipient: 'Recipient',
        opponent: 'Debitor/Recipient',
        addAccount: 'Add account',
        balanceIsNotEnough: 'The balance is not enough',
        newMoneyTransfer: 'New money transfer',
        newMoneyIncome: 'New money income',
        newMoneyExpenditure: 'New money expenditure',
        newFinanceExpenditure: 'New expenditure',
        newWithdraw: 'New withdraw',
        debitAmount: 'Debit amount',
        debitAmountHelp: 'Please enter debit amount',
        receiptCurrencyAccount: 'Receipt currency account',
        receiptCurrencyAccountHelp: 'Please choose receipt currency account',
        amount: 'Amount',
        amountHelp: 'Please enter amount',
        receiptAmount: 'Receipt amount',
        receiptAmountHelp: 'Please enter receipt amount',
        transfer: "Transfer",
        income: "Income",
        expenditure: "Expenditure",
        transfers: 'Transfers',
        receipts: 'Receipts',
        incomes: 'Incomes',
        withdrawals: 'Withdrawals',
        expenditures: 'Expenditures',
        category: 'Category',
        comment: 'Comment',
        newFinanceExpenditureCategory: 'New Category',
        sale: 'Sale',
        prepayment: 'Prepayment',
        debt: 'Debt'
    },
    warehouse: {
        productlist: 'Product list in warehouse',
        productIncomelist: 'Product Incomes list',
        productExpenditurelist: 'Product Expenditures list',
        receivingUser: 'Receiving user',
        submittingUser: 'Submitting user'
    },
    rollCalls: {
        EnterMessage: 'Enter message',
        ok: "Ok",
        start: "Start",
        expires: "Expires",
        tooFarFromTheOfficeError: ' You are too far from the office',
        question: 'Question',
        answerState: 'Answer',
        timestamp: 'Date/Time',
        history: 'History',
        locationRequired: 'Location required'
    },
    sales: {
        titleList: "Sales",
        paymentType: "Payment type",
        client: "Client",
        currencyAccount: "Currency account",
        total: 'Total',
        employee: 'Employee',
        created: 'Created',
        since: 'Since',
        before: 'Before',
        saleTitle: 'Sale',
        timestamp: 'Date/Time',
        clientCAState: 'Customer account status',
        currencyAccountState: 'Changing the company account'
    },
    receipts: {
        titleList: "Recipes",
        name: "Name",
        nameHelp: "Enter the recipe name",
        receipt: "Pecipe",
        doneproduct: "Product",
        doneproductAmount: "Quantity of finished products",
        newReceipt: "New recipe",
        create: 'Create',
        material: 'Material',
        materialAmount: 'Quantity',
        edit: 'Edit',
        receiptDuplicate: 'A recipe with this name already exists.'
    },
    dashboard: {
        costpriseTitle:'Себестоимость',
        costpriseDay: 'День',
        costpriseWeek: 'Неделя',
        costpriseMonth: 'Месяц',
        costpriseQuarter: 'Квартал',
        costpriseYear: 'Год',
    }
}