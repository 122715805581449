<script setup>
import FormControlIcon from '@/components/FormControlIcon.vue'
import { useMainStore } from '@/stores/main'
import VueDatePicker from '@vuepic/vue-datepicker'
import { VMoney } from 'v-money'
import '@vuepic/vue-datepicker/dist/main.css'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import VueMultiselect from 'vue-multiselect'
const moneyCfg = {
  decimal: ',',
  thousands: ' ',
  prefix: '',
  suffix: '',
  precision: props.precision,
  masked: false /* doesn't work with directive */
}
const { t } = useI18n()
const props = defineProps({
  name: {
    type: String,
    default: null
  },
  id: {
    type: String,
    default: null
  },
  autocomplete: {
    type: String,
    default: null
  },
  maxlength: {
    type: String,
    default: null
  },
  placeholder: {
    type: String,
    default: null
  },
  inputmode: {
    type: String,
    default: null
  },
  icon: {
    type: String,
    default: null
  },
  options: {
    type: Array,
    default: null
  },
  type: {
    type: String,
    default: 'text'
  },
  modelValue: {
    type: [String, Number, Boolean, Array, Object],
    default: ''
  },
  precision: { type: Number, default: 0 },
  required: Boolean,
  borderless: Boolean,
  transparent: Boolean,
  ctrlKFocus: Boolean,
  search: Boolean,
  allowEmpty: { type: Boolean, default: true },
  range: { type: Boolean, default: false },
  error: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  asyncFind: { type: Function, default: undefined }
})

const emit = defineEmits(['update:modelValue', 'setRef'])

const computedValue = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
  }
})

const inputElClass = computed(() => {
  const base = [
    'px-3 py-2 max-w-full focus:ring focus:outline-none border-gray-700 rounded w-full',
    'dark:placeholder-gray-400',
    computedType.value === 'textarea' ? 'h-24' : 'h-12',
    props.borderless ? 'border-0' : 'border',
    props.transparent ? 'bg-transparent' : 'bg-white dark:bg-slate-800',
    props.error ? 'outline outline-red-500' : ''
  ]

  if (props.icon) {
    base.push('pl-10')
  }

  return base
})

const computedType = computed(() =>
  props.options ? (props.search ? 'search-select' : 'select') : props.type
)

const controlIconH = computed(() => (props.type === 'textarea' ? 'h-full' : 'h-12'))

const mainStore = useMainStore()

const selectEl = ref(null)

const textareaEl = ref(null)

const inputEl = ref(null)

onMounted(() => {
  if (selectEl.value) {
    emit('setRef', selectEl.value)
  } else if (textareaEl.value) {
    emit('setRef', textareaEl.value)
  } else {
    emit('setRef', inputEl.value)
  }
})

if (props.ctrlKFocus) {
  const fieldFocusHook = (e) => {
    if (e.ctrlKey && e.key === 'k') {
      e.preventDefault()
      inputEl.value.focus()
    } else if (e.key === 'Escape') {
      inputEl.value.blur()
    }
  }

  onMounted(() => {
    if (!mainStore.isFieldFocusRegistered) {
      window.addEventListener('keydown', fieldFocusHook)
      mainStore.isFieldFocusRegistered = true
    } else {
      // console.error('Duplicate field focus event')
    }
  })

  onBeforeUnmount(() => {
    window.removeEventListener('keydown', fieldFocusHook)
    mainStore.isFieldFocusRegistered = false
  })
}
function nameWithLabel(option) {
  return option.label ?? option
}
const format = (dates) => {
  if (props.range) {
    const since = `${dates[0].getDate()}/${dates[0].getMonth() + 1}/${dates[0].getFullYear()}`
    if (dates[1]) {
      return `${since}-${dates[1].getDate()}/${dates[1].getMonth() + 1}/${dates[1].getFullYear()}`
    }
    return since
  }
  return `${dates.getDate()}/${dates.getMonth() + 1}/${dates.getFullYear()}`
}
</script>

<template>
  <div class="relative">
    <select
      v-if="computedType === 'select'"
      :id="id"
      v-model="computedValue"
      :name="name"
      :class="inputElClass"
    >
      <option v-for="option in options" :key="option.id ?? option" :value="option.id ?? option">
        {{ option.label ?? option }}
      </option>
    </select>
    <VueMultiselect
      v-else-if="computedType === 'search-select'"
      v-model="computedValue"
      :options="options"
      :close-on-select="true"
      :clear-on-select="false"
      style="height: 48px"
      :allow-empty="allowEmpty"
      :custom-label="nameWithLabel"
      :placeholder="t('selectoption')"
      :select-label="t('selectLabel')"
      :deselect-label="t('deselectLabel')"
      :selected-label="t('selectedLabel')"
      track-by="id"
      @search-change="asyncFind"
    >
      <template #noOptions>{{ t('List is empty') }}</template>
      <template #noResult>{{ t('No elements found') }}</template>
    </VueMultiselect>
    <textarea
      v-else-if="computedType === 'textarea'"
      :id="id"
      v-model="computedValue"
      :class="inputElClass"
      :name="name"
      :maxlength="maxlength"
      :placeholder="placeholder"
      :required="required"
    />
    <VueDatePicker
      v-else-if="computedType === 'datepicker'"
      v-model="computedValue"
      :range="range"
      :enable-time-picker="false"
      :format="format"
      :auto-apply="!range"
    ></VueDatePicker>
    <input
      v-else-if="computedType === 'money'"
      :id="id"
      ref="inputEl"
      v-model.lazy="computedValue"
      v-money="moneyCfg"
      :name="name"
      :maxlength="maxlength"
      :required="required"
      :placeholder="placeholder"
      :class="inputElClass"
      :disabled="disabled"
    />
    <!-- <input v-else-if="computedType === 'money'" type="tel" :value="formattedValue" @change="change" /> -->

    <input
      v-else
      :id="id"
      ref="inputEl"
      v-model="computedValue"
      :name="name"
      :maxlength="maxlength"
      :inputmode="inputmode"
      :autocomplete="autocomplete"
      :required="required"
      :placeholder="placeholder"
      :type="computedType"
      :class="inputElClass"
      :disabled="disabled"
    />
    <FormControlIcon
      v-if="icon && !(computedType === 'search-select')"
      :icon="icon"
      :h="controlIconH"
    />
  </div>
</template>
