<script setup>
import AsideMenuItem from '@/components/AsideMenu/AsideMenuItem.vue'
import { useUserStore } from '@/stores/user_store'
const userStore = useUserStore()

defineProps({
  isDropdownList: Boolean,
  menu: {
    type: Array,
    required: true
  }
})

const emit = defineEmits(['menu-click'])

const menuClick = (event, item) => {
  emit('menu-click', event, item)
}
</script>

<template>
  <ul>
    <AsideMenuItem
      v-for="(item, index) in menu.filter((i) =>
        i.permissions === undefined ? true : userStore.can(i.permissions)
      )"
      :key="index"
      :item="item"
      :is-dropdown-list="isDropdownList"
      @menu-click="menuClick"
    />
  </ul>
</template>
