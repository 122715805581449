import { defineStore } from 'pinia'
import { fetchWrapper } from '@/helpers/fetch-wrapper';
import { ref } from 'vue'

const baseUrl = `${import.meta.env.VITE_BASE_URL}`;

export const useDashboardStore = defineStore('dashboard', () => {
  const costPrices = ref(undefined)


  async function fetchCostProces(query = null) {
    let url = new URL(`${baseUrl}/dashboard/cost-price/`)
    url.searchParams.set('limit', '100000')
    if (query != null) {
      try {
        query.forEach((value, key) => url.searchParams.set(key, value))
      } catch (err) {
        console.log(err)
      }
    }
    return new Promise((resolve, reject) => {
      fetchWrapper.get(new String(url)).then((result) => {
        costPrices.value = result
        resolve(result)
      }).catch((error) => {
        reject(error)
      });
    })
  }

  return {
    costPrices,
    fetchCostProces
  }
})
