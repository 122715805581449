import { createI18n } from 'vue-i18n'
import uz from './locales/uz';
import ru from './locales/ru';
import en from './locales/en';


export default createI18n({
    // I18nScope: "global",
    globalInjection: true,
    legacy: false,
    allowComposition: true, // you must set `false`, to use Composition API
    locale: JSON.parse(localStorage.getItem('language')) || 'en', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages: {
        en, uz, ru
    },
})
