export default [
    {
        meta: {
            title: 'Materials',
            permissions: "materials"

        },
        path: '',
        name: 'materials-list',
        component: () => import('@/views/materials/MaterialsListView.vue')
    },
    {
        meta: {
            title: 'Materials',
            permissions: "materials"

        },
        path: 'warehouse',
        name: 'materials-warehouse',
        component: () => import('@/views/materials/MaterialsWarehouseView.vue')
    },
    {
        meta: {
            title: 'Materials',
            permissions: "materials"

        },
        path: 'warehouse/:id/',
        name: 'material-in-warehouse',
        component: () => import('@/views/materials/MaterialInWarehouseView.vue')
    }
]