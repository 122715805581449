export default [
    {
        meta: {
            title: 'Share Parts',
            permissions: "share_parts"

        },
        path: '',
        name: 'share-parts-list',
        component: () => import('@/views/shareParts/SharePartsListView.vue')
    },
    {
        meta: {
            title: 'Share Parts',
            permissions: "share_parts"

        },
        path: 'warehouse',
        name: 'share-parts-warehouse',
        component: () => import('@/views/shareParts/SharePartsWarehouseView.vue')
    },
    {
        meta: {
            title: 'Share Parts',
            permissions: "share_parts"

        },
        path: 'warehouse/:id/',
        name: 'share-part-in-warehouse',
        component: () => import('@/views/shareParts/SharePartInWarehouseView.vue')
    }
]