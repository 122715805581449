<script setup>
// import { useMainStore } from '@/stores/main'
import { useUserStore } from '@/stores/user_store'
import UserAvatar from '@/components/UserProfile/UserAvatar.vue'

// const mainStore = useMainStore()
const userStore = useUserStore()
</script>

<template>
  <UserAvatar :username="userStore.username" :avatar="userStore.avatar">
    <slot />
  </UserAvatar>
</template>
