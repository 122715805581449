<script setup>
import BaseIcon from '@/components/BaseIcon.vue'
import NavBarItemPlain from '@/components/NavBar/NavBarItemPlain.vue'
import NavBarMenuList from '@/components/NavBar/NavBarMenuList.vue'
import { containerMaxW } from '@/config.js'
import { mdiClose, mdiDotsVertical } from '@mdi/js'
import { ref } from 'vue'

defineProps({
  menu: {
    type: Array,
    required: true
  }
})

const emit = defineEmits(['menu-click'])

const menuClick = (event, item) => {
  emit('menu-click', event, item)
}

const isMenuNavBarActive = ref(false)
</script>

<template>
  <nav
    class="fixed inset-x-0 top-0 z-30 w-screen pt-2 xs:h-24 md:h-14 bg-gray-50 transition-position lg:w-auto dark:bg-slate-800"
  >
    <div class="flex bg-white lg:items-stretch dark:bg-slate-800" :class="containerMaxW">
      <!-- <div class="flex items-stretch flex-1 h-14" height: 5rem;> -->
      <div class="flex items-stretch flex-1" style="height: 4.5rem">
        <slot />
      </div>
      <div class="flex items-stretch flex-none xs:h-24 md:h-14 lg:hidden">
        <NavBarItemPlain @click.prevent="isMenuNavBarActive = !isMenuNavBarActive">
          <BaseIcon :path="isMenuNavBarActive ? mdiClose : mdiDotsVertical" size="24" />
        </NavBarItemPlain>
      </div>
      <div
        class="absolute left-0 w-screen overflow-y-auto shadow-lg max-h-screen-menu lg:overflow-visible top-14 bg-gray-50 lg:w-auto lg:flex lg:static lg:shadow-none dark:bg-slate-800"
        :class="[isMenuNavBarActive ? 'block' : 'hidden']"
      >
        <NavBarMenuList :menu="menu" @menu-click="menuClick" />
      </div>
    </div>
  </nav>
</template>
