export default [
    {
        meta: {
            title: 'Fabrication',
            permissions: "fabrication"

        },
        path: '',
        name: 'fabrications',
        component: () => import('@/views/fabrication/FabricationsListView.vue')
    },
    {
        meta: {
            title: 'Fabrication',
            permissions: "fabrication"

        },
        path: ':id',
        name: 'fabrication',
        component: () => import('@/views/fabrication/FabricationView.vue')
    },
]