import { computed } from 'vue'

import i18n from './i18n'

const { t } = i18n.global;

import {
  // mdiMenu,
  // mdiClockOutline,
  // mdiCloud,
  // mdiCrop,
  mdiAccount,
  // mdiCogOutline,
  // mdiEmail,
  mdiLogout,
  mdiThemeLightDark,
} from '@mdi/js'

export default computed(() => [
  // {
  //   icon: mdiMenu,
  //   label: 'Sample menu',
  //   menu: [
  //     {
  //       icon: mdiClockOutline,
  //       label: 'Item One'
  //     },
  //     {
  //       icon: mdiCloud,
  //       label: 'Item Two'
  //     },
  //     {
  //       isDivider: true
  //     },
  //     {
  //       icon: mdiCrop,
  //       label: 'Item Last'
  //     }
  //   ]
  // },
  {
    isCurrentUser: true,
    menu: [
      {
        icon: mdiAccount,
        label: t('menuNavBar.myprofile'),
        to: '/profile'
      },
      // {
      //   icon: mdiCogOutline,
      //   label: 'Settings'
      // },
      // {
      //   icon: mdiEmail,
      //   label: 'Messages'
      // },
      {
        isDivider: true
      },
      {
        icon: mdiLogout,
        label: t('menuNavBar.logout'),
        isLogout: true
      }
    ]
  },
  {
    icon: mdiThemeLightDark,
    label: t('menuNavBar.lightdark'),
    isDesktopNoLabel: true,
    isToggleLightDark: true
  },
]
)