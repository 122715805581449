export default [
    {
        meta: {
            title: 'Receipts',
            permissions: "receipts"

        },
        path: '',
        name: 'receipts',
        component: () => import('@/views/receipts/ReceiptsListView.vue')
    },
    {
        meta: {
            title: 'Receipts',
            permissions: "receipts"

        },
        path: ':id',
        name: 'receipt',
        component: () => import('@/views/receipts/ReceiptView.vue')
    },
]