<script setup>
import BaseIcon from '@/components/BaseIcon.vue'
import OnlinePrepaymentsAndDebts from '@/components/Clients/OnlinePrepaymentsAndDebts/OnlinePrepaymentsAndDebts.vue'
import SimpleCABalance from '@/components/CurrencyAccounts/SimpleCaBalance/SimpleCABalance.vue'
import menuAside from '@/menuAside.js'
import menuNavBar from '@/menuNavBar.js'
import { useDarkModeStore } from '@/stores/darkMode.js'
import { mdiBackburger, mdiCartVariant, mdiForwardburger, mdiMenu } from '@mdi/js'
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
// import FormControl from '@/components/FormControl.vue'
import AsideMenu from '@/components/AsideMenu/AsideMenu.vue'
import FooterBar from '@/components/FooterBar.vue'
import NavBar from '@/components/NavBar/NavBar.vue'
import NavBarItemPlain from '@/components/NavBar/NavBarItemPlain.vue'
import { useAuthStore } from '@/stores/auth_store'
import { useCartStore } from '@/stores/cart'
import { useUserStore } from '@/stores/user_store'

const userStore = useUserStore()
const auth = useAuthStore()
const cartStore = useCartStore()

// auth.testToken()
const layoutAsidePadding = 'xl:pl-60'

const darkModeStore = useDarkModeStore()

const route = useRoute()
const router = useRouter()

const isAsideMobileExpanded = ref(false)
const isAsideLgActive = ref(false)

router.beforeEach(() => {
  isAsideMobileExpanded.value = false
  isAsideLgActive.value = false
})

const menuClick = (event, item) => {
  if (item.isToggleLightDark) {
    darkModeStore.set()
  }

  if (item.isLogout) {
    //
    auth.logout()
  }
}
</script>

<template>
  <div
    :class="{
      'overflow-hidden lg:overflow-visible': isAsideMobileExpanded
    }"
  >
    <template v-if="userStore.can(['sales', 'cart'])">
      <router-link v-if="cartStore.cartLength && route.name !== 'cart'" :to="{ name: 'cart' }"
        ><span
          class="fixed z-50 inline-flex items-center justify-center p-5 rounded-full bg-slate-500 dark:bg-slate-300 sm:h-24 md:h-15 w-15 bottom-12 right-12"
        >
          <svg viewBox="0 0 24 24" :width="48" :height="48" class="inline-block">
            <path fill="currentColor" :d="mdiCartVariant" />
          </svg>
          <div
            class="absolute inline-flex items-center justify-center w-8 h-8 font-bold text-white bg-red-500 border-2 border-white rounded-full text-s -top-1 -end-1 dark:border-gray-900"
          >
            {{ cartStore.cartLength }}
          </div>
        </span></router-link
      >
    </template>
    <div
      :class="[layoutAsidePadding, { 'ml-60 lg:ml-0': isAsideMobileExpanded }]"
      class="w-screen h-full min-h-screen pt-24 transition-position lg:w-auto bg-gray-50 dark:bg-slate-800 dark:text-slate-100"
    >
      <NavBar
        :menu="menuNavBar"
        :class="[layoutAsidePadding, { 'ml-60 lg:ml-0 ': isAsideMobileExpanded }]"
        @menu-click="menuClick"
      >
        <NavBarItemPlain
          display="flex lg:hidden "
          @click.prevent="isAsideMobileExpanded = !isAsideMobileExpanded"
        >
          <BaseIcon :path="isAsideMobileExpanded ? mdiBackburger : mdiForwardburger" size="24" />
        </NavBarItemPlain>

        <NavBarItemPlain display="hidden lg:flex xl:hidden" @click.prevent="isAsideLgActive = true">
          <BaseIcon :path="mdiMenu" size="24" />
        </NavBarItemPlain>

        <div class="flex items-start justify-start w-full xs:flex-col md:flex-row lg:flex-row">
          <SimpleCABalance
            v-if="userStore.can(['currency_accounts_control', 'simple_ca_balance'])"
          />
          <OnlinePrepaymentsAndDebts
            v-if="userStore.can('online_prepayments_and_debts')"
            class="md:ml-2"
          />
        </div>
        <!-- <NavBarItemPlain use-margin>
          <FormControl placeholder="Search (ctrl+k)" ctrl-k-focus transparent borderless />
        </NavBarItemPlain> -->
      </NavBar>
      <AsideMenu
        :is-aside-mobile-expanded="isAsideMobileExpanded"
        :is-aside-lg-active="isAsideLgActive"
        :menu="menuAside"
        @menu-click="menuClick"
        @aside-lg-close-click="isAsideLgActive = false"
      />
      <slot />
      <FooterBar>
        <!-- Get more with
        <a href="https://tailwind-vue.justboil.me/" target="_blank" class="text-blue-600"
          >Premium version</a
        > -->
      </FooterBar>
    </div>
  </div>
</template>
