<script setup>
import BaseIcon from '@/components/BaseIcon.vue'
import LoaderInComponent from '@/components/LoaderInComponent.vue'
import { parseMoney } from '@/money-options'
import { mdiRefresh } from '@mdi/js'
import { onBeforeMount, onBeforeUnmount, ref } from 'vue'
import { useI18n } from 'vue-i18n'

// import { useCurrencyAccountsStore } from '@/stores/currencyAccounts'
import { useClientsStore } from '@/stores/clients'
const { t } = useI18n()

const clentsStore = useClientsStore()
// const currencyAccountsStore = useCurrencyAccountsStore()

// const items = computed(() => currencyAccountsStore.currencyAccountsSimple)
const prepaymentAndDebts = ref([])

const loadingVisible = ref(false)
const timerId = ref(undefined)

onBeforeMount(async () => {
  await handleClickRefresh()

  timerId.value = setInterval(async () => {
    await handleClickRefresh()
  }, 30000)
})
onBeforeUnmount(() => {
  if (timerId.value) {
    clearTimeout(timerId.value)
  }
})
async function handleClickRefresh() {
  loadingVisible.value = true
  clentsStore.fetchClientsPrepaymentsAndDebts().then((result) => {
    prepaymentAndDebts.value = result[0]
    setTimeout(() => {
      loadingVisible.value = false
    }, 500)
  })
}
</script>

<template>
  <div
    v-if="prepaymentAndDebts"
    class="flex items-center justify-start w-full mt-2 md:ml-2 md:pl-3 vld-parent"
  >
    <BaseIcon
      :path="mdiRefresh"
      class="mr-2 cursor-pointer d-none md:d-block xs:hidden md:flex"
      size="20"
      @click="handleClickRefresh"
    />
    <div class="flex flex-row justify-start w-full h-full">
      <!-- <div class="flex flex-col justify-center h-full md:flex-row"> -->
      <LoaderInComponent class="rounded-xl" :loading-visible="loadingVisible" />
      <div class="flex items-start justify-start flex-1 md:pr-4">
        <div class="flex flex-col md:mr-3">
          <div class="xs:text-[9px] md:text-[12px]">{{ t('currencyAccounts.prepayment') }}:</div>
          <div class="font-medium xs:text-[9px] md:text-[14px]">
            {{ parseMoney(prepaymentAndDebts.prepayment) }}
            {{ prepaymentAndDebts.currency?.toUpperCase() }}
          </div>
        </div>
      </div>
      <div
        class="flex items-start justify-start flex-1 pr-3 md:border-l md:dark:border-l-borderColor"
      >
        <div class="flex flex-col md:ml-3">
          <div class="xs:text-[9px] md:text-[12px]">{{ t('currencyAccounts.debt') }}:</div>
          <div class="font-medium xs:text-[9px] md:text-[14px]">
            {{ parseMoney(prepaymentAndDebts.debt) }}
            {{ prepaymentAndDebts.currency?.toUpperCase() }}
          </div>
        </div>
      </div>
      <div class="flex-1 md:hidden xl:flex"></div>
    </div>
  </div>
</template>
