export default [
    {
        meta: {
            title: 'Equipments',
            permissions: "equipments"

        },
        path: '',
        name: 'equipments-list',
        component: () => import('@/views/equipments/EquipmentsListView.vue')
    },
    {
        meta: {
            title: 'Equipments',
            permissions: "equipments"

        },
        path: 'warehouse',
        name: 'equipments-warehouse',
        component: () => import('@/views/equipments/EquipmentsWarehouseView.vue')
    },
    {
        meta: {
            title: 'Equipments',
            permissions: "equipments"

        },
        path: 'warehouse/:id/',
        name: 'equipment-in-warehouse',
        component: () => import('@/views/equipments/EquipmentInWarehouseView.vue')
    }
]