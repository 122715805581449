import { computed } from 'vue'

import i18n from './i18n'

const { t } = i18n.global;

import {
  mdiMonitor,
  mdiAccountGroupOutline,
  mdiAccountSearchOutline,
  mdiAccountCreditCardOutline,
  mdiCashMultiple,
  mdiStore,
  mdiShopping,
  mdiStoreSettings,
  mdiWarehouse,
  mdiFormatListBulleted,
  mdiChevronTripleDown,
  mdiChevronDoubleDown,
  mdiChevronTripleUp,
  mdiChevronDoubleUp,
  mdiCurrencyUsd,
  mdiSwapHorizontalBold,
  mdiReceiptOutline,
  mdiOfficeBuildingOutline
} from '@mdi/js'

export default computed(() => [
  {
    to: '/dashboard',
    icon: mdiMonitor,
    label: t('menuAside.dashboard')
  },
  {
    label: t('menuAside.clients'),
    icon: mdiAccountGroupOutline,
    permissions: "clients",
    menu: [
      {
        to: '/clients',
        label: t('menuAside.clientsList'),
        icon: mdiAccountSearchOutline
      },
      // {
      //   to: '/clients/credit-lines',
      //   label: t('menuAside.credits'),
      //   icon: mdiAccountCreditCardOutline
      // },
      {
        to: '/clients/currency-accounts',
        label: t('menuAside.accounts'),
        icon: mdiCashMultiple
      },
    ]
  },
  {
    label: t('menuAside.receipts'),
    icon: mdiReceiptOutline,
    permissions: "receipts",
    to: "/receipts"
  },
  {
    label: t('menuAside.fabrication'),
    icon: mdiOfficeBuildingOutline,
    permissions: "fabrication",
    to: "/fabrication"
  },
  {
    label: t('menuAside.doneProducts'),
    icon: mdiOfficeBuildingOutline,
    permissions: "doneproducts",
    menu: [
      {
        to: '/doneproducts',
        label: t('doneProducts.nomenclature'),
        icon: mdiFormatListBulleted
      },
      {
        to: '/doneproducts/warehouse',
        label: t('doneProducts.warehouse'),
        icon: mdiWarehouse
      },
    ]
  },
  {
    label: t('menuAside.materials'),
    icon: mdiStore,
    permissions: "materials",
    menu: [
      {
        to: '/materials',
        label: t('materials.nomenclature'),
        icon: mdiFormatListBulleted
      },
      {
        to: '/materials/warehouse',
        label: t('materials.warehouse'),
        icon: mdiWarehouse
      },
    ]
  },
  {
    label: t('menuAside.shareParts'),
    icon: mdiStore,
    permissions: "share_parts",
    menu: [
      {
        to: '/share-parts',
        label: t('shareParts.nomenclature'),
        icon: mdiFormatListBulleted
      },
      {
        to: '/share-parts/warehouse',
        label: t('shareParts.warehouse'),
        icon: mdiWarehouse
      },
    ]
  },
  {
    label: t('menuAside.equipments'),
    icon: mdiStore,
    permissions: "equipments",
    menu: [
      {
        to: '/equipments',
        label: t('equipments.nomenclature'),
        icon: mdiFormatListBulleted
      },
      {
        to: '/equipments/warehouse',
        label: t('equipments.warehouse'),
        icon: mdiWarehouse
      },
    ]
  },
  {
    label: t('menuAside.sales'),
    icon: mdiFormatListBulleted,
    to: '/sales',
    permissions: "sales",
  },
  // {
  //   label: t('menuAside.products'),
  //   icon: mdiStore,
  //   menu: [
  //     {
  //       to: '/products',
  //       label: t('menuAside.list'),
  //       icon: mdiShopping
  //     },
  //     {
  //       to: '/products/categories',
  //       label: t('menuAside.categories'),
  //       icon: mdiStoreSettings
  //     },
  //   ]
  // },
  {
    label: t('menuAside.currencyAccounts'),
    icon: mdiCurrencyUsd,
    permissions: "currency_accounts_control",
    menu: [
      {
        to: '/currency-accounts',
        label: t('menuAside.list'),
        icon: mdiFormatListBulleted,

      },
      {
        to: '/currency-accounts/receipts',
        label: t('currencyAccounts.receipts'),
        icon: mdiChevronDoubleDown
      },
      {
        to: '/currency-accounts/withdrawals',
        label: t('currencyAccounts.withdrawals'),
        icon: mdiChevronDoubleUp
      },
      // {
      //   to: '/currency-accounts/incomes',
      //   label: t('currencyAccounts.incomes'),
      //   icon: mdiChevronTripleDown
      // },
      // {
      //   to: '/currency-accounts/expenditures',
      //   label: t('currencyAccounts.expenditures'),
      //   icon: mdiChevronTripleUp
      // },
      // {
      //   to: '/currency-accounts/transfers',
      //   label: t('menuAside.transfers'),
      //   icon: mdiSwapHorizontalBold
      // },
    ]
  },
  // {
  //   label: t('menuAside.warehouse'),
  //   icon: mdiWarehouse,
  //   menu: [
  //     {
  //       to: '/warehouse/items-in-warehouse',
  //       label: t('menuAside.list'),
  //       icon: mdiFormatListBulleted
  //     },
  //     {
  //       to: '/warehouse/incomes',
  //       label: t('menuAside.warehouseIncomes'),
  //       icon: mdiChevronTripleDown
  //     },
  //     {
  //       to: '/warehouse/expenditures',
  //       label: t('menuAside.warehouseExpenditures'),
  //       icon: mdiChevronTripleUp
  //     },
  //   ]
  // },

]
)