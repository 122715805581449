import { defineStore } from 'pinia'
import { fetchWrapper } from '@/helpers/fetch-wrapper';
import { ref } from 'vue'

const baseUrl = `${import.meta.env.VITE_BASE_URL}`;

export const useCurrencyAccountsStore = defineStore('currencyAccounts', () => {
  const currencyAccounts = ref([])
  const currencyAccountsSimple = ref([{ account_type: 'cash', balance: 0 }, { account_type: 'non-cash', balance: 0 }])
  const currencyAccount = ref(undefined)
  const expenditures = ref([])
  const exchangeRate = ref(1)

  async function fetchCurrencyAccounts(query = null) {
    let url = new URL(`${baseUrl}/currency-accounts/currency-accounts/`)
    url.searchParams.set('limit', '100000')
    if (query != null) {
      try {
        query.forEach((value, key) => url.searchParams.set(key, value))
      } catch (err) {
        console.log(err)
      }
    }
    currencyAccounts.value = await getCurrencyAccounts(query)
    // await fetchWrapper.get(new String(url)).then((result) => {
    //   const results = result?.results
    //   // if (results.length) {
    //   currencyAccounts.value = results
    //   // }
    // }).catch((error) => {
    //   alert(error.message)
    // });
  }
  async function fetchCurrencyAccountsSimple(query = null) {
    let url = new URL(`${baseUrl}/currency-accounts/currency-accounts/get_simple_ca_balance/`)
    if (query != null) {
      try {
        query.forEach((value, key) => url.searchParams.set(key, value))
      } catch (err) {
        console.log(err)
      }
    }
    return new Promise((resolve, reject) => {
      fetchWrapper.get(new String(url)).then((result) => {
        const results = result?.results
        if (results.length) {
          currencyAccountsSimple.value = results
          resolve(results)
        } else { resolve([]) }
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }
  async function getCurrencyAccounts(query = null) {
    let url = new URL(`${baseUrl}/currency-accounts/currency-accounts/`)
    url.searchParams.set('limit', '100000')
    if (query != null) {
      try {
        query.forEach((value, key) => url.searchParams.set(key, value))
      } catch (err) {
        console.log(err)
      }
    }
    return new Promise((resolve, reject) => {
      fetchWrapper.get(new String(url)).then((result) => {
        const results = result?.results
        if (results.length) {
          resolve(results)
        } else { resolve([]) }
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }
  async function fetchCurrencyAccount(accountId) {
    await fetchWrapper.get(`${baseUrl}/currency-accounts/currency-accounts/${accountId}`).then((result) => {
      if (result) {
        currencyAccount.value = result
      }
    })
    // .catch((error) => {
    // alert(error.message)
    // });
  }
  async function fetchExchangeRate() {
    await fetchWrapper.get(`${baseUrl}/currency-accounts/get-course/`).then((result) => {
      if (result) {
        exchangeRate.value = result.course
      }
    })
  }
  async function addNewCurrencyAccount(account) {
    return new Promise((resolve, reject) => {
      fetchWrapper.post(`${baseUrl}/currency-accounts/currency-accounts/`, account).then((account) => {
        const results = [account, ...currencyAccounts.value]
        currencyAccounts.value = results
        resolve('ok')
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }

  async function fetchMoneyTransfers(query = null) {
    let url = new URL(`${baseUrl}/currency-accounts/money-transfers/`)
    url.searchParams.set('limit', '100000')
    if (query != null) {
      try {
        query.forEach((value, key) => url.searchParams.set(key, value))
      } catch (err) {
        console.log(err)
      }
    }

    return new Promise((resolve, reject) => {
      fetchWrapper.get(new String(url)).then((result) => {
        const results = result?.results
        if (results.length) {
          resolve(results)
        } else { resolve([]) }
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }
  async function addNewMoneyTransfer(transfer) {
    return new Promise((resolve, reject) => {
      fetchWrapper.post(`${baseUrl}/currency-accounts/money-transfers/`, transfer).then((transfer) => {
        const results = currencyAccounts.value
        results.forEach((item) => {
          if (item.id === transfer.debit_currency_account) {
            item.balance = parseFloat(item.balance) - parseFloat(transfer.debit_amount)
          } else if (item.id === transfer.receipt_currency_account) {
            item.balance = parseFloat(item.balance) + parseFloat(transfer.receipt_amount)
          }
        })
        currencyAccounts.value = results
        resolve(transfer)
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }
  async function fetchMoneyIncomes(query = null) {
    let url = new URL(`${baseUrl}/currency-accounts/money-incomes/`)
    url.searchParams.set('limit', '100000')
    if (query != null) {
      try {
        query.forEach((value, key) => url.searchParams.set(key, value))
      } catch (err) {
        console.log(err)
      }
    }

    return new Promise((resolve, reject) => {
      fetchWrapper.get(new String(url)).then((result) => {
        const results = result?.results
        if (results.length) {
          resolve(results)
        } else { resolve([]) }
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }
  async function addNewMoneyIncome(income) {
    return new Promise((resolve, reject) => {
      fetchWrapper.post(`${baseUrl}/currency-accounts/money-incomes/`, income).then((income) => {
        const results = currencyAccounts.value
        results.forEach((item) => {
          if (item.id === income.currency_account) {
            item.balance = parseFloat(item.balance) + parseFloat(income.amount)
          }
        })
        currencyAccounts.value = results
        resolve(income)
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }
  async function fetchMoneyExpenditures(query = null) {
    let url = new URL(`${baseUrl}/currency-accounts/money-expenditures/`)
    url.searchParams.set('limit', '100000')
    if (query != null) {
      try {
        query.forEach((value, key) => url.searchParams.set(key, value))
      } catch (err) {
        console.log(err)
      }
    }
    return new Promise((resolve, reject) => {
      fetchWrapper.get(new String(url)).then((result) => {
        const results = result?.results
        if (results.length) {
          expenditures.value = results
          resolve(results)
        } else { resolve([]) }
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }
  async function addNewMoneyExpenditure(expenditure) {
    return new Promise((resolve, reject) => {
      fetchWrapper.post(`${baseUrl}/currency-accounts/money-expenditures/`, expenditure).then((expenditure) => {
        const results = currencyAccounts.value
        results.forEach((item) => {
          if (item.id === expenditure.currency_account) {
            item.balance = parseFloat(item.balance) - parseFloat(expenditure.amount)
          }
        })
        currencyAccounts.value = results
        let exps = expenditures.value
        exps.unshift(expenditure)
        expenditures.value = exps
        resolve(expenditure)
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }
  async function fetchFinanceIncomes(query = null) {
    let url = new URL(`${baseUrl}/finance/incomes/`)
    url.searchParams.set('limit', '100000')
    if (query != null) {
      try {
        query.forEach((value, key) => url.searchParams.set(key, value))
      } catch (err) {
        console.log(err)
      }
    }

    return new Promise((resolve, reject) => {
      fetchWrapper.get(new String(url)).then((result) => {
        const results = result?.results
        if (results.length) {
          resolve(results)
        } else { resolve([]) }
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }
  async function fetchFinanceIncomeCategories(query = null) {
    let url = new URL(`${baseUrl}/finance/income_categories/`)
    url.searchParams.set('limit', '100000')
    if (query != null) {
      try {
        query.forEach((value, key) => url.searchParams.set(key, value))
      } catch (err) {
        console.log(err)
      }
    }

    return new Promise((resolve, reject) => {
      fetchWrapper.get(new String(url)).then((result) => {
        const results = result?.results
        if (results.length) {
          resolve(results)
        } else { resolve([]) }
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }
  async function fetchFinanceExpenditures(query = null) {
    let url = new URL(`${baseUrl}/finance/expendituries/`)
    url.searchParams.set('limit', '100000')
    if (query != null) {
      try {
        query.forEach((value, key) => url.searchParams.set(key, value))
      } catch (err) {
        console.log(err)
      }
    }

    return new Promise((resolve, reject) => {
      fetchWrapper.get(new String(url)).then((result) => {
        const results = result?.results
        if (results.length) {
          resolve(results)
        } else { resolve([]) }
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }
  async function fetchFinanceExpenditureCategories(query = null) {
    let url = new URL(`${baseUrl}/finance/expenditure_categories/`)
    url.searchParams.set('limit', '100000')
    if (query != null) {
      try {
        query.forEach((value, key) => url.searchParams.set(key, value))
      } catch (err) {
        console.log(err)
      }
    }

    return new Promise((resolve, reject) => {
      fetchWrapper.get(new String(url)).then((result) => {
        const results = result?.results
        if (results.length) {
          resolve(results)
        } else { resolve([]) }
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }
  async function addNewFinanceExpenditure(expenditure) {
    return new Promise((resolve, reject) => {
      fetchWrapper.post(`${baseUrl}/finance/expendituries/`, expenditure).then((expenditure) => {
        resolve(expenditure)
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }
  async function addNewFinanceCategoryExpenditure(category) {
    return new Promise((resolve, reject) => {
      fetchWrapper.post(`${baseUrl}/finance/expenditure_categories/`, category).then((category) => {
        resolve(category)
      }).catch((error) => {
        reject(error)
      });
    }
    )
  }

  return {
    currencyAccounts,
    currencyAccount,
    expenditures,
    currencyAccountsSimple,
    exchangeRate,
    fetchCurrencyAccounts,
    fetchCurrencyAccount,
    addNewCurrencyAccount,
    fetchMoneyTransfers,
    addNewMoneyTransfer,
    fetchMoneyIncomes,
    addNewMoneyIncome,
    fetchMoneyExpenditures,
    addNewMoneyExpenditure,
    fetchFinanceIncomes,
    fetchFinanceIncomeCategories,
    fetchFinanceExpenditures,
    fetchFinanceExpenditureCategories,
    addNewFinanceExpenditure,
    getCurrencyAccounts,
    addNewFinanceCategoryExpenditure,
    fetchCurrencyAccountsSimple,
    fetchExchangeRate
  }
})
